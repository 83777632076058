import * as Yup from 'yup'
import { EmailRegex } from '../../utils/regexTypes'

export default Yup.object().shape({
  email: Yup.string()
    .email('Email no válido')
    .matches(EmailRegex, 'Email no válido')
    .required('Ingrese un email'),
  password: Yup.string().required('Ingrese una contraseña'),
})
