import { FC, useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Button,
  Link as ChakraLink,
  Icon,
  Img,
  Text,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react'

import { colors } from '../../../utils/colors'
import { caseSvg, locationSvg } from '../../../utils/general'
import { usePercentage } from '../../../utils/hooks/usePercentage'

import { adCardProps } from './_types'
import { adCardStyles } from './styles'

import { jobApplicationRequest } from '../../../state/modules/ads/actions'
import institutionEmpty from '../../../assets/images/profile/InsitutionEmpty.png'
import Dialog from '../../Dialog/Dialog'
import { CloseIcon } from '@chakra-ui/icons'

const AdCard: FC<adCardProps> = ({ job }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userId = useSelector((state: any) => state?.user?.userInformation?.id)

  const [application, setApplication] = useState<boolean>(false)

  const percentage = usePercentage()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const publicationDate = new Date(job?.publicationDate)
    .toISOString()
    .split('T')[0]
    .split('-')
    .reverse()
    .join('-')

  const handleApplicationEvent = async () =>
    await dispatch(
      jobApplicationRequest({
        adId: job.id,
        token: localStorage.getItem('access_token'),
      }),
    )

  const handleApplication = () => {
    if (percentage === 100) {
      handleApplicationEvent()

      return
    }

    onOpen()
  }

  const handleEvent = (origin: string) => {
    onClose()

    return origin === 'goTo'
      ? navigate('/profile/user')
      : origin === 'close'
      ? handleApplicationEvent()
      : ''
  }

  useEffect(() => {
    if (userId && job?.JobApplications.length > 0) {
      const match = job?.JobApplications.find((e: any) => e.userId == userId)
      setApplication(match ? true : false)
    }
  }, [userId, job?.JobApplications])

  return (
    <Box sx={isMobile ? adCardStyles.containerMobile : adCardStyles.container}>
      <Box sx={isMobile ? adCardStyles.imageContainerMobile : adCardStyles.imageContainer}>
        <NavLink target='_blank' to={`/institution/detail/${job?.Institutions[0]?.id}`}>
          <Img
            sx={isMobile ? adCardStyles.avatarMobile : adCardStyles.avatarImage}
            alt={job?.Institutions[0]?.name}
            src={
              job.showInstitutionName !== false
                ? job?.Institutions[0]?.UserFiles[0]?.filePath
                  ? `${job?.Institutions[0]?.UserFiles[0]?.filePath}`
                  : institutionEmpty
                : institutionEmpty
            }
          />
        </NavLink>
      </Box>
      <Box sx={isMobile ? adCardStyles.infoContainerMobile : adCardStyles.infoContainer}>
        <Link to={`/careers/career/${job.id}`} target='_blank'>
          <Text sx={adCardStyles.title}>{job.title}</Text>
        </Link>
        <Box sx={isMobile ? adCardStyles.mainDataContainerMobile : adCardStyles.mainDataContainer}>
          <Box
            sx={
              isMobile
                ? adCardStyles.dataItemMobile1
                : { ...adCardStyles.dataItem, display: 'flex', flexWrap: 'wrap', columnGap: '5px' }
            }
          >
            {job?.Institutions?.map((e: any, i: number) =>
              job.showInstitutionName !== false ? (
                <NavLink
                  style={{
                    textTransform: 'capitalize',
                    marginLeft: '5px',
                  }}
                  to={`/institution/detail/${e.id}`}
                  target='_blank'
                  key={e.id}
                >
                  <ChakraLink>
                    {job?.Institutions?.length > 1 && '*'}
                    {e.name}
                  </ChakraLink>
                </NavLink>
              ) : (
                <Text key={e.id} sx={adCardStyles.adData}>
                  {job?.Institutions?.length > 1 && '*'}
                  Institución educativa
                </Text>
              ),
            )}
          </Box>

          <Box
            sx={
              isMobile
                ? adCardStyles.dataItemMobile2
                : { ...adCardStyles.dataItem, display: 'flex', columnGap: '5px' }
            }
          >
            <Text sx={adCardStyles.adDataTitle}>Fecha de publicación:</Text>
            <Text sx={adCardStyles.adData}>{publicationDate}</Text>
          </Box>
          <Box sx={adCardStyles.locationContainer}>
            {job?.Zones?.map((e: any) => (
              <Box sx={isMobile ? adCardStyles.dataItemMobile2 : adCardStyles.dataItem} key={e.id}>
                <Text sx={adCardStyles.adData}>
                  <Icon
                    fill={colors.principal}
                    height='15px'
                    width='15px'
                    viewBox={locationSvg.viewBox}
                  >
                    <path d={locationSvg.d}></path>
                  </Icon>{' '}
                  {e?.Province?.description.toLowerCase()} -{' '}
                  {e?.Department?.description.toLowerCase()} -{' '}
                  {e?.Locality?.description.toLowerCase()}
                </Text>
              </Box>
            ))}
          </Box>
          <Box
            sx={
              isMobile
                ? adCardStyles.dataItemMobile2
                : { ...adCardStyles.dataItem, display: 'flex', columnGap: '5px' }
            }
          >
            <Icon width='20px' height='20px' viewBox={caseSvg.viewBox} fill='none'>
              <path
                d={caseSvg.d}
                stroke={colors.principal}
                strokeWidth={caseSvg.strokeWidth}
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </Icon>
            <Text sx={adCardStyles.adData}>{job?.Position?.description}</Text>
          </Box>
        </Box>
      </Box>
      <Box sx={isMobile ? adCardStyles.actionContainerMobile : adCardStyles.actionContainer}>
        <Link to={`/careers/career/${job.id}`} target='_blank'>
          <Button sx={{ width: '100%', ...adCardStyles.actionButton }}>Ver</Button>
        </Link>
        {!application ? (
          <Button onClick={handleApplication} sx={{ ...adCardStyles.actionButton }}>
            Postular
          </Button>
        ) : (
          <Text sx={isMobile ? adCardStyles.appliedMobile : adCardStyles.applied}>Postulado</Text>
        )}
      </Box>
      <Dialog
        isOpen={isOpen}
        colorScheme='blue'
        eventLabel='Completa tu perfil'
        secondaryEventLabel='Postularse'
        onClose={() => handleEvent('close')}
        handleEvent={() => handleEvent('goTo')}
      >
        <>
          <Dialog.Header>
            <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
              <Text>¡Completá tu perfil!</Text>
              <CloseIcon cursor='pointer' onClick={() => handleEvent('iconClose')} />
            </Box>
          </Dialog.Header>
          <Dialog.Body>
            <Text>
              ¡Aumenta tus posibilidades de ser encontrado y accede a nuevas oportunidades!
            </Text>
          </Dialog.Body>
        </>
      </Dialog>
    </Box>
  )
}

export default AdCard
