import * as Yup from 'yup'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Select,
  Text,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  educationClean,
  educationRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { useDispatch, useSelector } from 'react-redux'

import { AddIcon } from '@chakra-ui/icons'
import ConfirmModal from '../../../confirmModal'
import CursosCompo from './educationcomp/cursos'
import EducationEdit from '../../forms-edit/form-education-edit'
import IdiomaComp from './educationcomp/idioma'
import TitleComponent from './educationcomp/TitleComponent'
import { formsStyles } from '../styles'

const toastMessages: UseToastOptions[] = [
  {
    description: 'Datos guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]

const Education = () => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [showTitle, setShowTitle] = useState<boolean>(false)
  const [showCourse, setShowCourse] = useState<boolean>(false)
  const [showLanguage, setShowLanguage] = useState<boolean>(false)

  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const dispatch = useDispatch()

  const { educationSuccess } = useSelector((state: any) => state?.user)

  const toast = useToast()

  const modalStyles = {
    buttonBox: { display: 'flex', flexDirection: 'row-reverse', justifyContent: 'end' },
  }

  useEffect(() => {
    if (educationSuccess !== null && educationSuccess !== undefined) {
      toast(toastMessages[educationSuccess ? 0 : 1])
      dispatch(educationClean())
    }
  }, [educationSuccess])

  const handleShow = (name: string) => {
    if (name === 'addTitle') {
      setShowTitle(true)
      setShowCourse(false)
      setShowLanguage(false)
    }
    if (name === 'addCourse') {
      setShowCourse(true)
      setShowTitle(false)
      setShowLanguage(false)
    }

    if (name === 'addLanguage') {
      setShowLanguage(true)
      setShowCourse(false)
      setShowTitle(false)
    }
  }

  return (
    <Box maxWidth='100%' margin='0 auto' display='flex' flexDirection='column' alignItems='center'>
      <Text size='xs' fontWeight='bold' textTransform='uppercase'>
        Datos Académicos
      </Text>

      <EducationEdit />

      <Box
        sx={{
          display: 'flex',
          width: `${isMobile ? '100%' : '50%'}`,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '24px',
        }}
      >
        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          title='¿Desea agregar otro item'
          buttonOne={'No'}
          buttonTwo={'Si'}
          onConfirm={() => window.location.reload()}
          styles={modalStyles}
        />
        {showTitle && (
          <TitleComponent
            setShowTitle={setShowTitle}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        )}
        <Button
          mt={4}
          name='addTitle'
          isDisabled={showTitle}
          sx={formsStyles.saveButton}
          width={isMobile ? 'auto' : '70%'}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleShow(e.currentTarget.name)
          }
        >
          <Text>Agregar título</Text>
          <AddIcon boxSize={4} ml={2} />
        </Button>

        {showCourse && (
          <CursosCompo
            setShowCourse={setShowCourse}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        )}
        <Button
          mt={4}
          name='addCourse'
          isDisabled={showCourse}
          sx={formsStyles.saveButton}
          width={isMobile ? 'auto' : '70%'}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleShow(e.currentTarget.name)
          }
        >
          <Text>Agregar curso</Text>
          <AddIcon boxSize={4} ml={2} />
        </Button>

        {showLanguage && (
          <IdiomaComp
            setShowLanguage={setShowLanguage}
            setIsConfirmModalOpen={setIsConfirmModalOpen}
          />
        )}
        <Button
          mt={4}
          name='addLanguage'
          isDisabled={showLanguage}
          width={isMobile ? 'auto' : '70%'}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
            handleShow(e.currentTarget.name)
          }
        >
          <Text>Agregar idioma</Text>
          <AddIcon boxSize={4} ml={2} />
        </Button>
      </Box>
    </Box>
  )
}

export default Education
