import * as Yup from 'yup'

export default Yup.object().shape({
  password: Yup.string()
    .matches(/^(?=.*[A-Za-z]{2,})(?=.*\d{2,})[A-Za-z\d]{14,}$/, {
      message:
        'La contraseña no cumple con las condiciones de seguridad: debe incluir al menos 2 números, 2 letras, tener al menos 14 caracteres y no debe incluir caracteres especiales',
    })
    .required('Contraseña requerida'),
})
