import {
  ActivateUserPaylaod,
  DeleteUserAssociationPayload,
  DeleteUserFromDbPaylaod,
  DeleteUserPaylaod,
  DownloadUserSheetPayload,
  EditUserAssociationPayload,
  EditUserPaylaod,
  FilterPayload,
  FindUsersPayload,
  HandleRequisitionPayload,
  InstitutionAssociationsPayload,
  InstitutionPayload,
  NewInstitutionPayload,
  NewUserAssociationPayload,
  NewUserPaylaod,
  RequisitionsInformationPayload,
  RolesInformationPayload,
  UpdateInstitutionDataPaylaod,
  VerificationEmailPaylaod,
} from './types'

import { AxiosPromise } from 'axios'
import httpModule from '../../../../utils/http'

const paths = {
  ROLES_INFORMATION: 'role/',
  USERS_LIST: 'users/list/',
  NEW_USER: 'users/abm/new-user/',
  DELETE_USER: 'users/',
  EDIT_USER: 'users/abm/',
  ACTIVATE_USER: 'users/abm/reactivate/',
  REQUISITIONS_INFORMATION: 'users/association-request/all/',
  HANDLE_REQUISITION: 'association-request/approve/',
  INSTITUTIONS_LIST: 'institution/list',
  NEW_INSTITUTION_LIST: 'institution/abm/create',
  FIND_USERS: 'users/email/',
  INSTITUTION_ASSOCIATIONS: 'user-institution/',
  NEW_USER_ASSOCIATION: 'user-institution/create',
  EDIT_USER_ASSOCIATION: 'user-institution/update',
  DELETE_USER_ASSOCIATION: 'user-institution/delete',
  DELETE_USER_FROM_DB: 'users/abm/',
  VERIFICATION_EMAIL: 'users/resendVerificationCode',
  UPDATE_INSTITUTION: 'institution/updateWithLogo/',
  DOWNLOAD_USER_SHEET: 'users/download-excel',
  DOWNLOAD_INSTITUTION_SHEET: 'institution/download-excel',
}

export const getRolesInformationService = (payload: RolesInformationPayload): AxiosPromise<any> =>
  httpModule.get(paths.ROLES_INFORMATION)

export const postUsersListService = (payload: FilterPayload): AxiosPromise<any> => {
  const filters = {
    lastName: payload.searchFilter?.lastname,
    name: payload.searchFilter?.name,
    dni: payload.searchFilter?.dni,
    email: payload.searchFilter?.email,
  }

  return httpModule.post(
    paths.USERS_LIST,
    { page: payload.currentPage, size: 10, filters: filters },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const postNewUserService = (payload: NewUserPaylaod): AxiosPromise<any> => {
  const user = {
    lastName: payload.lastname,
    name: payload.name,
    dni: payload.dni,
    email: payload.email,
    roleId: payload.userRole,
  }

  return httpModule.post(
    paths.NEW_USER,
    { ...user },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const deleteUserService = (payload: DeleteUserPaylaod): AxiosPromise<any> =>
  httpModule.delete(`${paths.DELETE_USER}${payload.userId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const putEditUserService = (payload: EditUserPaylaod): AxiosPromise<any> => {
  const profile = {
    id: payload.profileId,
    lastName: payload.lastname,
    name: payload.name,
    dni: payload.dni,
    cuil: payload.cuil,
    email: payload.email,
    phoneNumber: payload.phoneNumber,
    cellPhone: payload.cellPhone,
    gender: payload.gender,
    civilStatus: payload.civilStatus,
    birthdate: payload.birthdate,
    age: payload.age,
    nationalityId: payload.nationality,
    prepaidHealth: payload.prepaidHealth,
  }

  const address = {
    id: payload.addressId ?? null,
    fullAddress: null,
    provinceId: payload.provinceId ?? null,
    departmentId: payload.departmentId ?? null,
    localityId: payload.localityId ?? null,
    cityId: payload.localityId ?? null,
    street: payload.street ?? null,
    department: payload.department ?? null,
    number: payload.number ?? null,
    floor: payload.floor ?? null,
    postalCode: payload.postalCode ?? null,
  }

  return httpModule.put(
    `${paths.EDIT_USER}${payload.userId}`,
    { email: payload.email, roleId: payload.userRole, profile, address },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const putActivateUserService = (payload: ActivateUserPaylaod): AxiosPromise<any> =>
  httpModule.put(`${paths.ACTIVATE_USER}${payload.userId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const getRequisitionsInformationService = (
  payload: RequisitionsInformationPayload,
): AxiosPromise<any> => {
  return httpModule.get(`${paths.REQUISITIONS_INFORMATION}1`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const putHandleRequisitionService = (payload: HandleRequisitionPayload): AxiosPromise<any> =>
  httpModule.put(
    `users/${payload.requestId}/${paths.HANDLE_REQUISITION}${payload.approve}`,
    { roleId: payload.roleId },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
export const postInstitutionsListService = (payload: InstitutionPayload): AxiosPromise<any> => {
  const { institutionName, ...rest } = payload.filters

  const filters = {
    name: institutionName,
    ...rest,
  }

  return httpModule.post(
    paths.INSTITUTIONS_LIST,
    { page: payload.currentPage, size: payload.size, filters },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const postNewInstitutionListService = (
  payload: NewInstitutionPayload,
): AxiosPromise<any> => {
  const formData = new FormData()

  formData.append('data', JSON.stringify(payload.institution))

  if (payload.photoUp !== null) formData.append('photoUp', payload.photoUp, payload.photoUp.name)

  return httpModule.post(paths.NEW_INSTITUTION_LIST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `${payload.token}`,
    },
  })
}

export const getUsersService = (payload: FindUsersPayload): AxiosPromise<any> => {
  return httpModule.get(`${paths.FIND_USERS}${payload.query}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const getInstitutionAssociationsService = (
  payload: InstitutionAssociationsPayload,
): AxiosPromise<any> => {
  return httpModule.get(`${paths.INSTITUTION_ASSOCIATIONS}${payload.institutionId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const postNewUserAssociationService = (
  payload: NewUserAssociationPayload,
): AxiosPromise<any> => {
  const associationPayload = {
    userId: payload.userId,
    roleId: payload.roleId,
    institutionId: payload.institutionId,
  }

  return httpModule.post(paths.NEW_USER_ASSOCIATION, associationPayload, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const postEditUserAssociationService = (
  payload: EditUserAssociationPayload,
): AxiosPromise<any> => {
  const associationPayload = {
    id: payload.associationId,
    roleId: payload.roleId,
  }

  return httpModule.post(paths.EDIT_USER_ASSOCIATION, associationPayload, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const deleteUserAssociationService = (
  payload: DeleteUserAssociationPayload,
): AxiosPromise<any> => {
  return httpModule.delete(`${paths.DELETE_USER_ASSOCIATION}/${payload.targetId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })
}

export const deleteUserFromDbService = (payload: DeleteUserFromDbPaylaod): AxiosPromise<any> =>
  httpModule.delete(`${paths.DELETE_USER_FROM_DB}${payload.userId}`, {
    headers: {
      Authorization: `${payload.token}`,
    },
  })

export const postVerificationEmailService = (
  payload: VerificationEmailPaylaod,
): AxiosPromise<any> => {
  return httpModule.post(
    paths.VERIFICATION_EMAIL,
    { id: payload.userId },
    {
      headers: {
        Authorization: `${payload.token}`,
      },
    },
  )
}

export const putUpdateInstitutionDataService = (
  payload: UpdateInstitutionDataPaylaod,
): AxiosPromise<any> => {
  const formData = new FormData()

  const { institutionId, ...rest } = payload.data

  formData.append('data', JSON.stringify(rest))

  payload?.photoUp !== null && formData.append('photoUp', payload?.photoUp, payload?.photoUp.name)

  return httpModule.put(`${paths.UPDATE_INSTITUTION}${institutionId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `${payload.token}`,
    },
  })
}

export const postDownloadUserSheetService = (
  payload: DownloadUserSheetPayload,
): AxiosPromise<any> => {
  return httpModule.post(
    paths.DOWNLOAD_USER_SHEET,
    {},
    {
      headers: {
        Authorization: `${payload.token}`,
      },
      responseType: 'blob',
    },
  )
}

export const postDownloadInstitutionSheetService = (
  payload: DownloadUserSheetPayload,
): AxiosPromise<any> => {
  return httpModule.post(
    paths.DOWNLOAD_INSTITUTION_SHEET,
    {},
    {
      headers: {
        Authorization: `${payload.token}`,
      },
      responseType: 'blob',
    },
  )
}
