
import { Button, Grid, GridItem, HStack,Center, Heading } from '@chakra-ui/react'
import { landingStyles } from '../../../../pages/landing/styles'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'

import Icon1 from '../../../../assets/images/landing/grey_person.png'
import Icon2 from '../../../../assets/images/landing/vector2.png'
import Icon3 from '../../../../assets/images/landing/vector3.png'
import Icon4 from '../../../../assets/images/landing/vector4.png'

import PropTypes from 'prop-types'
import { FewStepsJob } from './FewStepsJob'

export const steps = [
  { title: 'Creá tu perfil', icon: Icon1 },
  { title: 'Encontrá tu nuevo trabajo', icon: Icon2 },
  { title: 'Recibí notificaciones de tus búsquedas', icon: Icon3 },
  { title: 'Elegí que datos mostrar', icon: Icon4 },
]
FewStepsJob.propTypes = {
  contPadding: PropTypes.string.isRequired,
}
export default FewStepsJob
