import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { createRef, FC, RefObject, useEffect, useState } from 'react'

import { Formik, Form } from 'formik'

import { PostRegisterPostulante } from '../../state/modules/login/services/types'

import LoginSchema from './_schema'

import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Text,
  Center,
  Stack,
  InputGroup,
  InputRightElement,
  Link,
  useToast,
  Checkbox,
  useBoolean,
  useMediaQuery,
  UseToastOptions,
  Button,
  // Button,
} from '@chakra-ui/react'

import BlueButton from '../buttons/button-blue'

import { colors } from '../../utils/colors'
import { EmailRegex } from '../../utils/regexTypes'

import { RegisterPostulante } from './_types'

import {
  registerPostulanteClean,
  registerPostulanteRequest,
} from '../../state/modules/login/actions'
import {
  termsAndConditionsClean,
  termsAndConditionsRequest,
} from '../../state/modules/user/actions'
import ReCAPTCHA from 'react-google-recaptcha'

const initialValues: RegisterPostulante = {
  name: '',
  lastName: '',
  dni: '',
  email: '',
  password: '',
  rePassword: '',
}

const toastMessages: UseToastOptions[] = [
  {
    description:
      'Muchas gracias, estará llegando un email a su casilla de correo para la activación',
    status: 'success',
    duration: 4000,
  },
  {
    description: 'El DNI o email ingresado ya se encuentra registrado',
    status: 'error',
    duration: 2000,
  },
  {
    description: 'Muchas gracias, su usuario se encuentra activo',
    status: 'success',
    duration: 4000,
  },
  {
    description: 'Error de conexión. Por favor intente más tarde',
    status: 'error',
    duration: 2000,
  },
]
const RegisterCompo: FC = () => {
  const toast = useToast()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useBoolean()
  const [showRePassword, setShowRePassword] = useBoolean()

  const recaptchaRef = createRef<ReCAPTCHA>()
  const [isChecked, setIsChecked] = useState(false)
  const [passwordError, setPasswordError] = useState('')
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const [isRegistering, setIsRegistering] = useState(false)
  const [isCaptchaVerified, setIsCaptchaVerified] = useState<boolean>(false)

  const { registerPostulanteSuccess, statusRegisterPostulante } = useSelector(
    (state: any) => state.login,
  )
  const { termsAndConditionsSuccess, termsAndConditionsStatus } = useSelector(
    (state: any) => state?.user,
  )

  useEffect(() => {
    if (
      registerPostulanteSuccess !== null &&
      registerPostulanteSuccess !== undefined &&
      statusRegisterPostulante?.isVerified === false
    ) {
      toast(toastMessages[0])
      dispatch(registerPostulanteClean())
      setIsRegistering(false)
    }

    if (registerPostulanteSuccess === false) {
      if (!statusRegisterPostulante.response) {
        toast({ description: statusRegisterPostulante.message, status: 'error', duration: 2000 })
      } else toast(toastMessages[1])

      dispatch(registerPostulanteClean())
      setIsRegistering(false)
    }

    if (
      registerPostulanteSuccess !== null &&
      registerPostulanteSuccess !== undefined &&
      statusRegisterPostulante?.isVerified === true
    ) {
      toast(toastMessages[2])
      dispatch(registerPostulanteClean())
      setIsRegistering(false)
    }
  }, [registerPostulanteSuccess])

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked)
  }

  const handleCaptchaChange = (token: string | null) => {
    setIsCaptchaVerified(Boolean(token))
  }
  const onSubmit = (values: RegisterPostulante) => {
    if (values.password !== values.rePassword) {
      setPasswordError('Las contraseñas no coinciden')
      return
    }

    const updatedInitialValuesForm: PostRegisterPostulante = {
      name: values.name,
      lastName: values.lastName,
      dni: values.dni,
      email: values.email,
      password: values.password,
      navigate: navigate,
    }
    setIsRegistering(true)

    dispatch(registerPostulanteRequest(updatedInitialValuesForm))
  }

  useEffect(() => {
    if (
      termsAndConditionsSuccess &&
      termsAndConditionsSuccess !== null &&
      termsAndConditionsStatus
    ) {
      const link = document.createElement('a')
      const blobURL = URL.createObjectURL(termsAndConditionsStatus)
      link.href = blobURL
      link.target = '_blank'
      link.click()

      dispatch(termsAndConditionsClean())
    }

    if (termsAndConditionsSuccess === false) {
      toast({
        description: termsAndConditionsStatus.response?.data?.msg,
        status: 'error',
        duration: 2000,
      })

      dispatch(termsAndConditionsClean())
    }
  }, [termsAndConditionsSuccess])

  const handleClickTermsAndConditions = () => {
    dispatch(termsAndConditionsRequest())
  }

  return (
    <Box p={isMobile ? 8 : 0}>
      <Center>
        <Heading as='h4' size='md' mb={4}>
          Creá tu cuenta
        </Heading>
      </Center>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={LoginSchema}>
        {({ values, errors, touched, handleChange, handleBlur, setErrors }) => (
          <Form>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel htmlFor='name' fontSize={'sm'}>
                  Nombre
                </FormLabel>
                <Input
                  id='name'
                  name='name'
                  type='text'
                  fontSize={'sm'}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.name}
                  </Text>
                ) : null}
                <FormLabel htmlFor='lastName' fontSize={'sm'}>
                  Apellido
                </FormLabel>
                <Input
                  id='lastName'
                  name='lastName'
                  type='text'
                  fontSize={'sm'}
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.lastName && touched.lastName ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.lastName}
                  </Text>
                ) : null}
                <FormLabel htmlFor='dni' fontSize={'sm'}>
                  DNI
                </FormLabel>
                <Input
                  id='dni'
                  name='dni'
                  type='number'
                  fontSize={'sm'}
                  value={values.dni}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={8}
                />
                {errors.dni && touched.dni ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.dni}
                  </Text>
                ) : null}
                <FormLabel htmlFor='email' fontSize={'sm'}>
                  Email
                </FormLabel>
                <Input
                  id='email'
                  name='email'
                  type='text'
                  fontSize={'sm'}
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={(e: any) => {
                    handleChange(e)
                  }}
                />
                {errors.email && touched.email ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.email}
                  </Text>
                ) : null}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password && touched.password)}>
                <FormLabel htmlFor='password' fontSize={'sm'}>
                  Contraseña
                </FormLabel>
                <InputGroup>
                  <Input
                    id='password'
                    name='password'
                    type={showPassword ? 'text' : 'password'}
                    fontSize={'sm'}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minLength={14}
                  />
                  <InputRightElement>
                    {showPassword ? (
                      <ViewOffIcon cursor={'pointer'} onClick={setShowPassword.toggle} />
                    ) : (
                      <ViewIcon cursor={'pointer'} onClick={setShowPassword.toggle} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors.password && touched.password ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.password}
                  </Text>
                ) : (
                  <Text fontSize={'xs'} mt='1'>
                    Debe incluir al menos 2 números, 2 letras y tener al menos 14 caracteres
                  </Text>
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.password && touched.password)}>
                <FormLabel htmlFor='rePassword' fontSize={'sm'}>
                  Repetir contraseña
                </FormLabel>
                <InputGroup>
                  <Input
                    id='rePassword'
                    name='rePassword'
                    type={showRePassword ? 'text' : 'password'}
                    fontSize={'sm'}
                    value={values.rePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    minLength={14}
                  />
                  <InputRightElement>
                    {showRePassword ? (
                      <ViewOffIcon cursor={'pointer'} onClick={setShowRePassword.toggle} />
                    ) : (
                      <ViewIcon cursor={'pointer'} onClick={setShowRePassword.toggle} />
                    )}
                  </InputRightElement>
                </InputGroup>
                {errors.rePassword && touched.rePassword ? (
                  <Text color='red' fontSize={'sm'}>
                    {errors.rePassword}
                  </Text>
                ) : null}
                {passwordError && (
                  <Text color='red' fontSize={'sm'}>
                    {passwordError}
                  </Text>
                )}
              </FormControl>

              <Box>
                <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>
                  <Text
                    fontSize={'10px'}
                    fontWeight={'normal'}
                    align={isMobile ? 'center' : 'inherit'}
                  >
                    Acepto los{' '}
                    <Button
                      fontSize={'10px'}
                      color={colors.principal}
                      variant='link'
                      onClick={handleClickTermsAndConditions}
                    >
                      términos y condiciones
                    </Button>{' '}
                    de EDUprivada
                  </Text>
                </Checkbox>
              </Box>
              <Box>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY!}
                  onChange={handleCaptchaChange}
                  ref={recaptchaRef as RefObject<ReCAPTCHA>}
                />
              </Box>
              <Box>
                {Boolean(values.password) &&
                Boolean(values.rePassword) &&
                touched.rePassword &&
                values.password !== values.rePassword ? (
                  <Text color='red' fontSize={'sm'}>
                    Las contraseñas no coinciden.
                  </Text>
                ) : (
                  ''
                )}
                <BlueButton
                  type='submit'
                  width='full'
                  isLoading={isRegistering}
                  isDisabled={
                    !isChecked ||
                    Object.keys(errors).length > 0 ||
                    values.password !== values.rePassword ||
                    !isCaptchaVerified
                  }
                >
                  <Text size={'sm'} fontWeight='bold'>
                    Confirmar
                  </Text>
                </BlueButton>
              </Box>
            </Stack>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default RegisterCompo
