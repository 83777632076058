import * as Yup from 'yup'
import { EmailRegex } from '../../utils/regexTypes'

export default Yup.object().shape({
  name: Yup.string().required('Ingrese un nombre'),
  lastName: Yup.string().required('Ingrese un apellido'),
  dni: Yup.string()
    .matches(/^\d{8}$/, 'DNI inválido')
    .required('Ingrese un DNI de 8 dígitos'),
  email: Yup.string()
    .email('Email no válido')
    .matches(EmailRegex, 'Email no válido')
    .required('Ingrese un email'),
  password: Yup.string()
    .matches(/^(?=.*[A-Za-z]{2,})(?=.*\d{2,})[A-Za-z\d]{14,}$/, {
      message:
        'La contraseña no cumple con las condiciones de seguridad: debe incluir al menos 2 números, 2 letras, tener al menos 14 caracteres y no debe incluir caracteres especiales',
    })
    .required('Contraseña requerida'),
  rePassword: Yup.string().required('Repetir contraseña requerida'),
})
