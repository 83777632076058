export function haveNewValue(obj1: any, obj2: any) {
  let haveNewValue = false

  for (const element in obj1) {
    if (obj2[element] !== obj1[element]) {
      haveNewValue = true
      break
    }

    haveNewValue = false
  }

  return haveNewValue
}
