import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

export const usePercentage = (): number => {
  const userInformation = useSelector((state: any) => state?.user?.userInformation ?? '')

  const [percentage, setPercentage] = useState<number>(0)

  const chargePercentage = () => {
    const basePercentage = 20
    const additionalPercentage = 20

    const { UserFiles, UserTitles, JobExperiences, Preferences } = userInformation

    const fields = [UserFiles, UserTitles, JobExperiences, Preferences]

    const percentageResult: number = fields.reduce(
      (total, field) => total + (field?.length !== 0 ? additionalPercentage : 0),

      basePercentage,
    )

    setPercentage(percentageResult)
  }

  useEffect(() => {
    if (userInformation) chargePercentage()
  }, [userInformation])

  return percentage
}
