import imageLandingMedium from '../../../../assets/images/landing/institutional/banner-instituciones-web.png'
import circlesOnlyMobile from '../../../../assets/images/landing/circles_only_mobile.svg'
import { Box, Center, Heading, Stack ,Button, Flex, HStack, Text, useBreakpoint, useMediaQuery } from '@chakra-ui/react'
import { landingStyles } from '../../../../pages/landing/styles'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { colors } from '../../../../utils/colors'
import { headerLandingStyles } from '../../regular/header-landing/styles'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

const HeaderInstLanding: FC<LandingMarginProps> = ({ contPadding }) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const brkpnt = useBreakpoint('sm');
  const overlapPoint = brkpnt === 'base' || brkpnt === 'sm' || brkpnt === 'md' || brkpnt === 'lg'

  const navigate = useNavigate()
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  const handleActionBtn = () => {
    if (isLogged) {
      console.log('acción botón...')
    } else {
      navigate('/login')
    }
  }

  return (
    <Box
      display='flex'
      height={{ base: '300px', sm: '380px', md: '380px', lg: '528px', xl: '528px', '2xl': '530px' }}
      width='100vw'
      alignItems={['center', 'center']}
      gap='2'
      position='relative'
      pt='2'
      pl={contPadding}
      bgImage={(overlapPoint) ? circlesOnlyMobile : imageLandingMedium}
      bgPosition={{ base: 'right bottom', md: '85% bottom', lg: 'right bottom', xl: 'right bottom', '2xl': 'right 75%' }}
      bgSize={['cover', 'cover']}
      bgRepeat='no-repeat'
    >
      <Box
        position='absolute'
        top={['5%', '15%']}
        left={['0%', '0']}
        display='flex'
        alignItems='flex-start'
        flexDirection='column'
        alignContent='flex-start'
        justifyContent='space-evenly'
        pl={contPadding}
      >
        <Box
          mb={{ base: '18px', md: '20px', lg: '30px', xl: '30px', '2xl': '46px' }}
        >
          <Text
            fontSize={{ base: '1.8rem', sm: '33px', md: '48px', lg: '52px', xl: '52px', '2xl': '68px' }}
            lineHeight={{ base: '36px', sm: '50px', md: '50px', lg: '60.75px', xl: '60.75px', '2xl': '82px' }}
            textAlign={'left'}
            width={'100%'}
            color='#004876'
            fontWeight={400}
          >
            <Text as='span' color='#E03F59'> Encontrar candidatos <br />nunca fue</Text> más simple
          </Text>
        </Box>
        <Box
          width='100%'
          color='#004876'
          fontSize={{ base: '20px', sm: '20px', md: '24px', lg: '28px', xl: '28px', '2xl': '34px' }}
          mb={{ base: '18px',sm: '20px', md: '20px', lg: '30px', xl: '30px', '2xl': '46px' }}
        >
          {isMobile ?
            <Text> Conectamos talentos <br /> con oportunidades</Text> :
            <Text> Conectamos talentos con oportunidades</Text>
          }
        </Box>
        <Box
          color='#004876'
          fontSize={['18px', '18px']}
        >
          <Button
            sx={headerLandingStyles.actionButton}
            size={{ base: 'sm', md: 'md', lg: 'lg' }}
            css={{
              '@media screen and (min-width: 1536px)': {
                width: '350px',
                height: '65px',
                fontSize: '38px',
                borderRadius: '10px'
              }
            }}
            onClick={handleActionBtn}
          >
            {Buttons.PUBLISH_YOUR_AD}</Button>
        </Box>
      </Box>
    </Box >
  )
}

export default HeaderInstLanding
