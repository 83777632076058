import { ReactElement, useRef } from 'react'

import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogCloseButton,
} from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  eventLabel: string
  onClose: () => void
  children: ReactElement
  handleEvent: () => void
  secondaryEventLabel: string
  colorScheme:
    | string
    | 'whiteAlpha'
    | 'blackAlpha'
    | 'gray'
    | 'red'
    | 'orange'
    | 'yellow'
    | 'green'
    | 'teal'
    | 'blue'
    | 'cyan'
    | 'purple'
    | 'pink'
    | 'linkedin'
    | 'facebook'
    | 'messenger'
    | 'whatsapp'
    | 'twitter'
    | 'telegram'
    | undefined
}

interface HeaderProps {
  fontSize?: string
  fontWeight?: string
  closeIcon?: boolean
  children: ReactElement
}

const Dialog = (props: Props): ReactElement => {
  const cancelRef = useRef<HTMLButtonElement>(null)

  const {
    isOpen,
    onClose,
    children,
    eventLabel,
    colorScheme,
    handleEvent,
    secondaryEventLabel = 'Cancelar',
  } = props

  return (
    <>
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            {children}
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                {secondaryEventLabel}
              </Button>
              <Button colorScheme={colorScheme} onClick={handleEvent} ml={3}>
                {eventLabel}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

const Header = ({
  fontSize = 'lg',
  fontWeight = 'bold',
  children,
  closeIcon,
}: HeaderProps): ReactElement => (
  <>
    <AlertDialogHeader fontSize={fontSize} fontWeight={fontWeight}>
      {children}
    </AlertDialogHeader>
    {closeIcon ? <AlertDialogCloseButton /> : ''}
  </>
)

const Body = ({ children }: { children: ReactElement }): ReactElement => (
  <AlertDialogBody>{children}</AlertDialogBody>
)

Dialog.Header = Header
Dialog.Body = Body

export default Dialog
