import { Box, Button, Icon, Modal, Text, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import {
  activateUserClean,
  deleteUserClean,
  deleteUserFromDbClean,
  downloadUserSheetClean,
  editUserClean,
  newUserClean,
  usersClean,
  usersRequest,
  verificationMailClean,
} from '../../../state/modules/abm/actions'
import { useDispatch, useSelector } from 'react-redux'

import AbmModal from '../AbmModal'
import { AddIcon } from '@chakra-ui/icons'
import UsersFilter from './UsersFilter'
import { UsersStyles } from './styles'
import UsersTable from './UsersTable'
import { downloadAdsSpreadsheetClean } from '../../../state/modules/ads/actions'

const Users: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>('1')
  const [currentUser, setCurrentUser] = useState<any>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [searchFilter, setSearchFilter] = useState<any>(null)
  const dispatch = useDispatch()
  const toast = useToast()
  const abm = useSelector((state: any) => state?.abm)
  const toastDuration = 2000

  const handleShow = (option: string) => {
    setShowModal((prev: boolean) => !prev)
    setSelectedOption(option)
  }

  const payload = {
    name: '',
    lastName: '',
    email: '',
    dni: '',
  }
  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(usersRequest({ ...payload, token, currentPage, searchFilter }))
  }, [
    currentPage,
    searchFilter,
    abm?.deleteUserSuccess,
    abm?.newUserSuccess,
    abm?.editUserSuccess,
    abm?.activateUserSuccess,
    abm?.deleteUserFromDbSuccess,
    abm?.verificationEmailSuccess,
  ])

  useEffect(() => {
    if (abm?.newUserSuccess) {
      toast({
        description: abm?.newUserStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(newUserClean())
      setShowModal(false)
    }
    if (abm?.newUserSuccess === false) {
      toast({
        description: abm?.newUserStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(newUserClean())
    }
  }, [abm?.newUserSuccess])

  useEffect(() => {
    if (abm?.deleteUserSuccess) {
      toast({
        description: abm?.deleteUserStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(deleteUserClean())
    }

    if (abm?.deleteUserSuccess === false) {
      toast({
        description: abm?.deleteUserStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(deleteUserClean())
    }
  }, [abm?.deleteUserSuccess])

  useEffect(() => {
    if (abm?.editUserSuccess) {
      toast({
        description: abm?.editUserStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      setShowModal(false)
      dispatch(editUserClean())
    }

    if (abm?.editUserSuccess === false) {
      toast({
        description: abm?.editUserStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(editUserClean())
    }
  }, [abm?.editUserSuccess])

  useEffect(() => {
    if (abm?.activateUserSuccess) {
      toast({
        description: abm?.activateUserStatus?.status,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(activateUserClean())
    }

    if (abm?.activateUserSuccess === false) {
      toast({
        description: abm?.activateUserStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(activateUserClean())
    }
  }, [abm?.activateUserSuccess])

  useEffect(() => {
    if (abm?.deleteUserFromDbSuccess) {
      toast({
        description: abm?.deleteUserFromDbStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(deleteUserFromDbClean())
    }

    if (abm?.deleteUserFromDbSuccess === false) {
      toast({
        description: abm?.deleteUserFromDbStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(deleteUserFromDbClean())
    }
  }, [abm?.deleteUserFromDbSuccess])

  useEffect(() => {
    if (abm?.verificationEmailSuccess) {
      toast({
        description: abm?.verificationEmailStatus?.message,
        status: 'success',
        duration: toastDuration,
      })
      dispatch(verificationMailClean())
    }

    if (abm?.verificationEmailSuccess === false) {
      toast({
        description: abm?.verificationEmailStatus?.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(verificationMailClean())
    }
  }, [abm?.verificationEmailSuccess])

  useEffect(() => {
    if (
      abm?.downloadUserSheetSuccess &&
      abm?.downloadUserSheetStatus !== null &&
      abm?.downloadUserSheetStatus !== undefined
    ) {
      const link = document.createElement('a')
      const myBlob = abm?.downloadUserSheetStatus
      link.href = URL.createObjectURL(myBlob)
      link.download = `Export_usuarios_${new Date().toLocaleDateString('en-US')}.xlsx`
      link.click()

      toast({
        description: 'Planilla descargada correctamente',
        status: 'success',
        duration: toastDuration,
      })
      dispatch(downloadUserSheetClean())
    }

    if (abm?.downloadUserSheetSuccess === false) {
      toast({
        description: abm?.downloadUserSheetStatus.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })
      dispatch(downloadUserSheetClean())
    }
  }, [abm?.downloadUserSheetSuccess])

  return (
    <Box sx={UsersStyles.box}>
      <AbmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        selectedOption={selectedOption}
        currentUser={currentUser}
      />
      <UsersFilter setSearchFilter={setSearchFilter} setCurrentPage={setCurrentPage} />
      <Box sx={UsersStyles.addUserSection}>
        <Button sx={UsersStyles.addUser} onClick={() => handleShow('1')} colorScheme='neutral'>
          Agregar usuario
          <Icon sx={UsersStyles.icon} as={AddIcon} />
        </Button>

        <Text sx={UsersStyles.usersQuantity}>
          {abm?.usersInformation?.activeUsers && abm?.usersInformation?.activeUsers === 1
            ? `${abm?.usersInformation?.activeUsers} Usuario activo`
            : `${abm?.usersInformation?.activeUsers} Usuarios activos`}
        </Text>
      </Box>
      <UsersTable
        showModal={handleShow}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setCurrentUser={setCurrentUser}
        searchFilter={searchFilter}
      />
    </Box>
  )
}

export default Users
