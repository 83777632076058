import { Box,Flex,Text} from '@chakra-ui/react'

// import Icon1 from '../../../assets/images/landing/vector1.png'
import inst1 from '../../../../assets/images/landing/inst3.png'
import inst2 from '../../../../assets/images/landing/inst2.png'

import CommentCard from './comment-card'
import { FC } from 'react'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
// import Icon3 from '../../../assets/images/landing/vector3.png'
// import Icon4 from '../../../assets/images/landing/vector4.png'
export interface IsInstitutional {
  isInstit?: boolean
}
const OurUsersSays: FC<IsInstitutional & LandingMarginProps> = ({ isInstit, contPadding }) => {
  const usersComments = {
    regular: [
      {
        id: 1,
        comment: 'Como docente encuentro que Eduprivada hace más eficiente mi proceso de búsquedas laborales. Puedo realizar búsquedas específicas a la vez que por la gran cantidad de avisos que hay y la sencilla forma de compartirlos puedo recomendar ofertas a mi red de contactos.',
        personName: 'Mariela',
        jobTitle: 'Personal Docente',
        avatarImg: null
      },
      {
        id: 2,
        comment: 'A diferencia de otras plataformas, que Eduprivada sea específica del ámbito educativo privado nacional me da la oportunidad de pensar en alternativas más desafiantes donde poner mis habilidades, competencias y saberes en práctica.',
        personName: 'Ariel',
        jobTitle: 'Personal Docente. Secretario',
        avatarImg: null
      }
    ],
    institutional: [
      {
        id: 1,
        comment: 'Eduprivada se convirtió en nuestra  herramienta preferida a la hora de realizar búsquedas de RRHH. Es única ya que refleja las necesidades que se presentan en Instituciones Educativas específicamente. En la experiencia del usuario se nota que quienes la realizan conocen el ámbito educativo.',
        personName: 'Marisa',
        jobTitle: 'Representante Legal San Ignacio de Loyola',
        avatarImg: inst1
      },
      {
        id: 2,
        comment: 'Esta excelente iniciativa resulta  una idea innovadora que pone en contacto los recursos humanos con las instituciones educativas de gestión privada de forma eficiente, sin intermediarios.',
        personName: 'María Belén',
        jobTitle: 'Directora del Nivel Secundario Instituto Buenos Aires',
        avatarImg: inst2
      }
    ]
  }

  const mappedComments = isInstit ? usersComments.institutional : usersComments.regular
  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      width={['100%']}
      px={contPadding}
    >
      <Box my='5'>
        <Text
          fontSize={['1.4rem', '2.1rem']}
          textAlign={'center'}
          width={'100%'}
          color='#004876'
        >
          Lo que nuestros usuarios dicen
        </Text>
      </Box>
      <Flex
        flexDirection="row"
        flexWrap='wrap'
        gap={{ base: '20px', md: '5px', lg: '20px' }}
        justifyContent={['center', 'space-between']}
        alignContent={['center', 'center']}
        width={['100%', '100%']}
      >
        {mappedComments.map(comment =>
          <CommentCard comment={comment} isInstit={isInstit} key={comment.id} />
        )}
      </Flex>
    </Flex>
  )
}

export default OurUsersSays
