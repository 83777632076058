import { Flex, HStack, Text, Button, useMediaQuery, useBreakpoint,Box, Center, Heading, Stack } from '@chakra-ui/react'
import imageLandingMedium from '../../../../assets/images/landing/Banner-home-web.png'
import circlesOnlyMobile from '../../../../assets/images/landing/circles_only_mobile.svg'
import { landingStyles } from '../../../../pages/landing/styles'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { colors } from '../../../../utils/colors'
import { headerLandingStyles } from './styles'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
import PropTypes from 'prop-types'

const HeaderLanding: React.FC<LandingMarginProps> = ({ contPadding }) => {
  const navigate = useNavigate()
  const [isLogged, setIsLogged] = useState(false)
  const brkpnt = useBreakpoint('sm');
  const overlapPoint = brkpnt === 'base' || brkpnt === 'sm' || brkpnt === 'md' || brkpnt === 'lg'

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  useEffect(() => {
    console.log(brkpnt)
  }, [])

  const handleActionBtn = () => {
    if (isLogged) {
      console.log('acción botón...')
    } else {
      navigate('/login')
    }
    // window.location.reload()
  }

  return (
    <Box
      display='flex'
      height={{ base: '300px', sm: '380px', md: '380px', lg: '528px', xl: '500px', '2xl': '530px' }}
      width={['100vw', '100vw']}
      alignItems={['center', 'center']}
      position='relative'
      pt='2'
      pl={contPadding}
      bgImage={(overlapPoint) ? circlesOnlyMobile : imageLandingMedium}
      bgPosition={{
        base: '82% bottom',
        sm: 'right bottom',
        md: 'right bottom',
        lg: '85% bottom',
        xl: 'right bottom',
        '2xl': 'right bottom'
      }}
      bgSize={{ base: 'cover', sm: 'cover', md: 'cover', lg: 'cover', xl: 'cover', '2xl': 'cover' }}
      bgRepeat='no-repeat'
    >
      <Box
        position='absolute'
        top={['15%', '15%']}
        display='flex'
        alignItems='flex-start'
        flexDirection='column'
        alignContent='flex-start'
        justifyContent='space-evenly'
      >
        <Box
          mb={{ base: '18px', sm: '20px', md: '20px', lg: '30px', xl: '30px', '2xl': '46px' }}
        >
          <Text
            fontSize={{ base: '1.8rem', sm: '33px', md: '48px', lg: '50px', xl: '58px', '2xl': '62px' }}
            lineHeight={{ base: '36px', sm: '50px', md: '50px', lg: '60.75px', xl: '60.75px', '2xl': '72px' }}
            textAlign={'left'}
            width={'100%'}
            color='#004876'
            fontWeight={400}
          >La  <Text as='span' color='#E03F59'> red</Text> de empleos en <br /><Text as='span' color='#E03F59'>escuelas  privadas</Text> de <br /><Text as='span' color='#E03F59'>Argentina</Text>
          </Text>
        </Box>
        <Box
          width='100%'
          color='#004876'
          fontSize={{ base: '19px', sm: '18px', md: '24px', lg: '27px', xl: '30px', '2xl': '32px' }}
          mb={{ base: '18px', sm: '24px', md: '20px', lg: '30px', xl: '30px', '2xl': '46px' }}
        >
          Conectamos talentos con oportunidades
        </Box>
        <Box
          color='#004876'
          fontSize={['18px', '18px']}
        >
          <Button
            sx={headerLandingStyles.actionButton}
            size={{ base: 'sm', md: 'md', lg: 'md' }}
            css={{
              '@media screen and (min-width: 1536px)': {
                width: '450px',
                height: '70px',
                fontSize: '30px',
                borderRadius: '10px'
              },
            }}
            onClick={handleActionBtn}
          > {Buttons.LANDING_ACTION_BUTTON}</Button>
        </Box>
      </Box>
    </Box >
  )
}
HeaderLanding.propTypes = {
  contPadding: PropTypes.string.isRequired,
}
export default HeaderLanding
