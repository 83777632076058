import { Button, Flex, Grid, GridItem, HStack, Text, useBreakpoint, useMediaQuery, Box, Center, Heading, Stack } from '@chakra-ui/react'
import { landingStyles } from '../../../../pages/landing/styles'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { colors } from '../../../../utils/colors'
import mockAds from './mockAds.json'
import Advice from './advice'
import { featuredAdvicesStyles } from './styles'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { adLastTenRequest } from '../../../../state/modules/ads/actions'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
import PropTypes from 'prop-types'

const FeaturedAdvices: React.FC<LandingMarginProps> = ({ contPadding }) => {
    const deviceWidth = useBreakpoint()
    const [isMobile] = useMediaQuery('(max-width: 600px)')
    const [isTablet] = useMediaQuery('(max-width: 768px)')
    const [width, setWidth] = useState(0)

    const dispatch = useDispatch()
    const [isLogged, setIsLogged] = useState(false)
    const [localAds, setLocalAds] = useState<any[]>([]);
    const { ads, hasErrMessage } = useSelector((state: any) => state.ads);

    const cardGap = 40
    const isLargeViewport = width > 1440
    const responsiveMargin = deviceWidth === 'base' ? 42.5 : 170
    const calcCardWidth = (isLargeViewport) ?
        '412px' : (isMobile) ? '100%' :
            `${((width - responsiveMargin) - (cardGap * 1)) / ((isTablet) ? 2 : 3)}px`

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [setWidth])


    const navigate = useNavigate()
    useEffect(() => {
        dispatch(adLastTenRequest());

        const session = localStorage.getItem('access_token')
        if (session !== null) setIsLogged(true)
    }, [dispatch]);

    useEffect(() => {
        if (ads && ads.length > 0) {
            setLocalAds(ads);
        } else {
            console.log('No ads available or ads is empty');
        }
    }, [ads]);

    const handleActionBtn = () => {
        if (isLogged) {
            console.log('acción botón...')
        } else {
            navigate('/login')
        }
        window.location.reload()
    }

    const maprResultsRender = () => {
        const resultsRender = []
        const condQuant = (isTablet || localAds.length === 4) ? 2 : localAds.length
        for (let i = 0; i < localAds.length; (isMobile) ? i += 2 : i += condQuant) {
            resultsRender.push(
                <Box
                    display='flex'
                    justifyContent={['space-between']}
                    alignContent={['center', 'center']}
                    flexDirection="row"
                    flexWrap={'wrap'}
                    gap={(isLargeViewport || isMobile) ? '40px' : '0'}
                    w='100%'
                >
                    {localAds.slice(i, i + condQuant)
                        .map(ad => (
                            <Advice key={i} ad={ad} calcCardWidth={calcCardWidth} />
                        ))}
                </Box>
            )
        }
        return resultsRender
    }

    return (
        <Flex
            flexDirection={['column', 'column']}
            justifyItems={['center', 'center']}
            alignItems={['center', 'center']}
            width={['100%', '100%']}
            px={contPadding}
        >
            <Box mb={['45px', '90px']}>
                <Text
                    fontSize={['1.5rem', '2.5rem']}
                    textAlign={'center'}
                    width={'100%'}
                    color='#004876'
                >
                    Avisos destacados
                </Text>
            </Box>

            {maprResultsRender()}

            <Box>
                <Button
                    sx={featuredAdvicesStyles.actionButton}
                    size={{ base: 'sm', md: 'md', lg: 'lg' }}
                    onClick={handleActionBtn}
                    style={{ borderWidth: '1px' }}
                >
                    Ver más avisos
                </Button>
            </Box>
        </Flex>
    )
}

FeaturedAdvices.propTypes = {
    contPadding: PropTypes.string.isRequired,
}
export default FeaturedAdvices
