import { Box, Text,Image, Img } from '@chakra-ui/react'
import image from './advertisingBanner.png'

const Advertsing = () => {
  return (
    <Box
      width={'100%'}
      marginBottom={['32px', 0]}
      height={'100%'}
      display='flex'
      justifyContent='center'
      alignItems='start'
    >
      <Image src={image} alt='advertising' />
    </Box>
  )
}

export default Advertsing
