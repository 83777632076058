import * as Yup from 'yup'

import { Box, Button, FormControl, FormLabel, Input, Text } from '@chakra-ui/react'
import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { Fields, FilterFields, userFilterProps } from './_types'
import React, { useState } from 'react'

import { downloadUserSheetRequest } from '../../../../state/modules/abm/actions'
import { useDispatch } from 'react-redux'
import { usersFields } from '../../../../utils/abm'
import { usersFilterStyles } from './styles'
import { EmailRegex } from '../../../../utils/regexTypes'

const UsersFilter: React.FC<userFilterProps> = ({ setSearchFilter, setCurrentPage }) => {
  const [isClean, setIsClean] = useState<boolean>(true)
  const filterFields = usersFields.filter((e) => e.isFiltered).sort((e1, e2) => e1.order - e2.order)

  const dispatch = useDispatch()

  const initialValues: FilterFields = {
    name: '',
    lastname: '',
    email: '',
    dni: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Email no válido')
      .matches(EmailRegex, 'Email no válido')
      .required('Ingrese un email'),
    dni: Yup.string()
      .test({
        name: 'is-number',
        test(value, ctx) {
          if (value && value.length > 0 && Number.isNaN(Number(value))) {
            return ctx.createError({ message: 'El campo solo acepta números' })
          }
          return true
        },
      })
      .min(7, 'Mínimo de 7 caracteres')
      .max(8, 'Máximo de 8 caracteres'),
  }).defined()
  const handleSubmit = (values: FilterFields) => {
    setCurrentPage(1)
    setSearchFilter(values)
    setIsClean(false)
  }

  const handleReset = (reset: any) => {
    setCurrentPage(1)
    setSearchFilter(null)
    reset()
    setIsClean(true)
  }

  const handleExport = () => {
    const token = localStorage.getItem('token') ?? ''
    dispatch(downloadUserSheetRequest({ token }))
  }

  return (
    <Box sx={usersFilterStyles.box}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ resetForm, errors, touched, setFieldValue, values }) => {
          const touchedFields = touched as FormikTouched<any>
          const errorFields = errors as FormikErrors<any>

          return (
            <Form>
              <Box sx={usersFilterStyles.form}>
                {filterFields.map((e) => {
                  const targetElement = e as Fields
                  return (
                    <FormControl sx={usersFilterStyles.field} key={e.order}>
                      <FormLabel sx={usersFilterStyles.label} htmlFor={e.id}>
                        {targetElement.name}
                      </FormLabel>
                      <Field
                        as={Input}
                        type='text'
                        name={targetElement.id}
                        maxLength={targetElement.id === 'dni' ? 8 : 100}
                        value={values[targetElement.id as keyof FilterFields]}
                        onBlur={() =>
                          setFieldValue(
                            `${targetElement.id}`,
                            `${values[targetElement.id as keyof FilterFields]}`.trim(),
                          )
                        }
                        onChange={(event: any) =>
                          setFieldValue(event.target.name, event.target.value.replace(/ /g, ''))
                        }
                      />
                      {errorFields[`${targetElement.id}`] &&
                        touchedFields[`${targetElement.id}`] && (
                          <Text color='red' fontSize={'sm'}>
                            {`${errorFields[targetElement.id]}`}
                          </Text>
                        )}
                    </FormControl>
                  )
                })}
              </Box>
              <Box sx={usersFilterStyles.buttonsBox}>
                <Button sx={usersFilterStyles.searchButton} type='submit'>
                  Buscar
                </Button>
                <Button sx={usersFilterStyles.export} onClick={handleExport}>
                  Exportar{' '}
                </Button>
                <Button sx={usersFilterStyles.cleanFilters} onClick={() => handleReset(resetForm)}>
                  Limpiar filtros
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default UsersFilter
