import * as types from './types'

import {
  ModuleTypeActivateUser,
  ModuleTypeAssociationsInformation,
  ModuleTypeDeleteUser,
  ModuleTypeDeleteUserAssociation,
  ModuleTypeDeleteUserFromDb,
  ModuleTypeDownloadUserSheet,
  ModuleTypeEditUser,
  ModuleTypeEditUserAssociation,
  ModuleTypeFoundUsers,
  ModuleTypeHandleRequisition,
  ModuleTypeInstitutionsInformation,
  ModuleTypeNewInstitution,
  ModuleTypeNewUser,
  ModuleTypeNewUserAssociation,
  ModuleTypeRequisitionsiInformation,
  ModuleTypeRolesInformation,
  ModuleTypeUpdateInstitutionData,
  ModuleTypeUsersInformation,
  ModuleTypeVerificationEmail,
} from './_definitions'

import { PayloadAction } from '../../types'
import { abm } from './_ducks'

const initialModuleStateRolesInformation: ModuleTypeRolesInformation = {
  hasErrMessage: false,
  rolesInformation: null,
}

const initialModuleStateUsersInformation: ModuleTypeUsersInformation = {
  hasErrMessage: false,
  usersInformation: null,
}

const initialModuleNewUser: ModuleTypeNewUser = {
  hasErrMessage: false,
  newUserStatus: null,
  newUserSuccess: null,
}

const initialModuleDeleteUser: ModuleTypeDeleteUser = {
  hasErrMessage: false,
  deleteUserSuccess: null,
  deleteUserStatus: null,
}

const initialModuleEditUser: ModuleTypeEditUser = {
  hasErrMessage: false,
  editUserSuccess: null,
  editUserStatus: null,
}

const initialModuleActivateUser: ModuleTypeActivateUser = {
  hasErrMessage: false,
  activateUserSuccess: null,
  activateUserStatus: null,
}

const initialModuleRequisitionsInformation: ModuleTypeRequisitionsiInformation = {
  hasErrMessage: false,
  requisitionsInformation: null,
}

const initialModuleHandleRequisition: ModuleTypeHandleRequisition = {
  hasErrMessage: false,
  handleRequisitionSuccess: null,
  handleRequisitionStatus: null,
}

const initialModuleStateInstitutionsInformation: ModuleTypeInstitutionsInformation = {
  hasErrMessage: false,
  institutionsInformation: null,
}

const initialModuleNewInstitution: ModuleTypeNewInstitution = {
  hasErrMessage: false,
  newInstitutionSuccess: null,
  newInstitutionStatus: null,
}

const initialModuleStateFoundUsers: ModuleTypeFoundUsers = {
  hasErrMessage: false,
  foundUsers: null,
}

const initialModuleStateAssociationsInformation: ModuleTypeAssociationsInformation = {
  hasErrMessage: false,
  associationsInformation: null,
}

const initialModuleNewUserAssociation: ModuleTypeNewUserAssociation = {
  hasErrMessage: false,
  newUserAssociationSuccess: null,
  newUserAssociationStatus: null,
}
const initialModuleEditUserAssociation: ModuleTypeEditUserAssociation = {
  hasErrMessage: false,
  editUserAssociationSuccess: null,
  editUserAssociationStatus: null,
}

const initialModuleDeleteUserAssociation: ModuleTypeDeleteUserAssociation = {
  hasErrMessage: false,
  deleteUserAssociationSuccess: null,
  deleteUserAssociationStatus: null,
}

const initialModuleDeleteUserFromDb: ModuleTypeDeleteUserFromDb = {
  hasErrMessage: false,
  deleteUserFromDbSuccess: null,
  deleteUserFromDbStatus: null,
}

const initialModuleVerificationEmail: ModuleTypeVerificationEmail = {
  hasErrMessage: false,
  verificationEmailSuccess: null,
  verificationEmailStatus: null,
}

const initialModuleUpdateInstitutionData: ModuleTypeUpdateInstitutionData = {
  hasErrMessage: false,
  updateInstitutionDataSuccess: null,
  updateInstitutionDataStatus: null,
}

const initialModuleDownloadUserSheet: ModuleTypeDownloadUserSheet = {
  hasErrMessage: false,
  downloadUserSheetSuccess: null,
  downloadUserSheetStatus: null,
}

const reducer = abm.createReducer(
  {
    [types.ROLES_GET_REQUEST]: (state: ModuleTypeRolesInformation): ModuleTypeRolesInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.ROLES_GET_SUCCESS]: (
      state: ModuleTypeRolesInformation,
      { payload }: PayloadAction,
    ): ModuleTypeRolesInformation => ({
      ...state,
      rolesInformation: payload.roles,
    }),
    [types.ROLES_GET_FAILURE]: (state: ModuleTypeRolesInformation): ModuleTypeRolesInformation => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.ROLES_GET_CLEAN]: (state: ModuleTypeRolesInformation): ModuleTypeRolesInformation => ({
      ...state,
      hasErrMessage: false,
      rolesInformation: null,
    }),
    [types.USERS_POST_REQUEST]: (
      state: ModuleTypeUsersInformation,
    ): ModuleTypeUsersInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USERS_POST_SUCCESS]: (
      state: ModuleTypeUsersInformation,
      { payload }: PayloadAction,
    ): ModuleTypeUsersInformation => ({
      ...state,
      usersInformation: payload.users,
    }),
    [types.USERS_POST_FAILURE]: (
      state: ModuleTypeUsersInformation,
    ): ModuleTypeUsersInformation => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.USERS_POST_CLEAN]: (state: ModuleTypeUsersInformation): ModuleTypeUsersInformation => ({
      ...state,
      hasErrMessage: false,
      usersInformation: null,
    }),

    [types.NEW_USER_POST_REQUEST]: (state: ModuleTypeNewUser): ModuleTypeNewUser => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.NEW_USER_POST_SUCCESS]: (
      state: ModuleTypeNewUser,
      { payload }: PayloadAction,
    ): ModuleTypeNewUser => ({
      ...state,
      newUserSuccess: true,
      newUserStatus: payload,
    }),
    [types.NEW_USER_POST_FAILURE]: (
      state: ModuleTypeNewUser,
      { payload }: PayloadAction,
    ): ModuleTypeNewUser => ({
      ...state,
      newUserSuccess: false,
      newUserStatus: payload,
      hasErrMessage: true,
    }),
    [types.NEW_USER_POST_CLEAN]: (state: ModuleTypeNewUser): ModuleTypeNewUser => ({
      ...state,
      hasErrMessage: false,
      newUserSuccess: null,
      newUserStatus: null,
    }),
    [types.USER_DELETE_REQUEST]: (state: ModuleTypeDeleteUser): ModuleTypeDeleteUser => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteUser,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteUser => ({
      ...state,
      deleteUserSuccess: true,
      deleteUserStatus: payload,
    }),
    [types.USER_DELETE_FAILURE]: (
      state: ModuleTypeDeleteUser,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteUser => ({
      ...state,
      deleteUserSuccess: false,
      deleteUserStatus: payload,
      hasErrMessage: true,
    }),
    [types.USER_DELETE_CLEAN]: (state: ModuleTypeDeleteUser): ModuleTypeDeleteUser => ({
      ...state,
      hasErrMessage: false,
      deleteUserSuccess: null,
      deleteUserStatus: null,
    }),
    [types.USER_EDIT_REQUEST]: (state: ModuleTypeEditUser): ModuleTypeEditUser => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_EDIT_SUCCESS]: (
      state: ModuleTypeEditUser,
      { payload }: PayloadAction,
    ): ModuleTypeEditUser => ({
      ...state,
      editUserSuccess: true,
      editUserStatus: payload,
    }),
    [types.USER_EDIT_FAILURE]: (
      state: ModuleTypeEditUser,
      { payload }: PayloadAction,
    ): ModuleTypeEditUser => ({
      ...state,
      editUserSuccess: false,
      editUserStatus: payload,
      hasErrMessage: true,
    }),
    [types.USER_EDIT_CLEAN]: (state: ModuleTypeEditUser): ModuleTypeEditUser => ({
      ...state,
      hasErrMessage: false,
      editUserSuccess: null,
      editUserStatus: null,
    }),
    [types.USER_ACTIVATE_REQUEST]: (state: ModuleTypeActivateUser): ModuleTypeActivateUser => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_ACTIVATE_SUCCESS]: (
      state: ModuleTypeActivateUser,
      { payload }: PayloadAction,
    ): ModuleTypeActivateUser => ({
      ...state,
      activateUserSuccess: true,
      activateUserStatus: payload,
    }),
    [types.USER_ACTIVATE_FAILURE]: (
      state: ModuleTypeActivateUser,
      { payload }: PayloadAction,
    ): ModuleTypeActivateUser => ({
      ...state,
      activateUserSuccess: false,
      activateUserStatus: payload,
      hasErrMessage: true,
    }),
    [types.USER_ACTIVATE_CLEAN]: (state: ModuleTypeActivateUser): ModuleTypeActivateUser => ({
      ...state,
      hasErrMessage: false,
      activateUserSuccess: null,
      activateUserStatus: null,
    }),
    [types.REQUISITIONS_GET_REQUEST]: (
      state: ModuleTypeRequisitionsiInformation,
    ): ModuleTypeRequisitionsiInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.REQUISITIONS_GET_SUCCESS]: (
      state: ModuleTypeRequisitionsiInformation,
      { payload }: PayloadAction,
    ): ModuleTypeRequisitionsiInformation => ({
      ...state,
      requisitionsInformation: payload,
    }),
    [types.REQUISITIONS_GET_FAILURE]: (
      state: ModuleTypeRequisitionsiInformation,
    ): ModuleTypeRequisitionsiInformation => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.REQUISITIONS_GET_CLEAN]: (
      state: ModuleTypeRequisitionsiInformation,
    ): ModuleTypeRequisitionsiInformation => ({
      ...state,
      hasErrMessage: false,
      requisitionsInformation: null,
    }),
    [types.REQUISITION_HANDLE_PUT_REQUEST]: (
      state: ModuleTypeHandleRequisition,
    ): ModuleTypeHandleRequisition => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.REQUISITION_HANDLE_PUT_SUCCESS]: (
      state: ModuleTypeHandleRequisition,
      { payload }: PayloadAction,
    ): ModuleTypeHandleRequisition => ({
      ...state,
      hasErrMessage: false,
      handleRequisitionSuccess: true,
      handleRequisitionStatus: payload,
    }),
    [types.REQUISITION_HANDLE_PUT_FAILURE]: (
      state: ModuleTypeHandleRequisition,
      { payload }: PayloadAction,
    ): ModuleTypeHandleRequisition => ({
      ...state,
      hasErrMessage: true,
      handleRequisitionSuccess: false,
      handleRequisitionStatus: payload,
    }),
    [types.REQUISITION_HANDLE_PUT_CLEAN]: (
      state: ModuleTypeHandleRequisition,
    ): ModuleTypeHandleRequisition => ({
      ...state,
      hasErrMessage: false,
      handleRequisitionSuccess: null,
      handleRequisitionStatus: null,
    }),
    [types.INSTITUTIONS_POST_REQUEST]: (
      state: ModuleTypeInstitutionsInformation,
    ): ModuleTypeInstitutionsInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.INSTITUTIONS_POST_SUCCESS]: (
      state: ModuleTypeInstitutionsInformation,
      { payload }: PayloadAction,
    ): ModuleTypeInstitutionsInformation => ({
      ...state,
      institutionsInformation: payload.institutions,
    }),
    [types.INSTITUTIONS_POST_FAILURE]: (
      state: ModuleTypeInstitutionsInformation,
    ): ModuleTypeInstitutionsInformation => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.INSTITUTIONS_POST_CLEAN]: (
      state: ModuleTypeInstitutionsInformation,
    ): ModuleTypeInstitutionsInformation => ({
      ...state,
      hasErrMessage: false,
      institutionsInformation: null,
    }),
    [types.NEW_INSTITUTION_POST_REQUEST]: (
      state: ModuleTypeNewInstitution,
    ): ModuleTypeNewInstitution => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.NEW_INSTITUTION_POST_SUCCESS]: (
      state: ModuleTypeNewInstitution,
      { payload }: PayloadAction,
    ): ModuleTypeNewInstitution => ({
      ...state,
      hasErrMessage: false,
      newInstitutionSuccess: true,
      newInstitutionStatus: payload,
    }),
    [types.NEW_INSTITUTION_POST_FAILURE]: (
      state: ModuleTypeNewInstitution,
      { payload }: PayloadAction,
    ): ModuleTypeNewInstitution => ({
      ...state,
      hasErrMessage: true,
      newInstitutionSuccess: false,
      newInstitutionStatus: payload,
    }),
    [types.NEW_INSTITUTION_POST_CLEAN]: (
      state: ModuleTypeNewInstitution,
    ): ModuleTypeNewInstitution => ({
      ...state,
      hasErrMessage: false,
      newInstitutionSuccess: null,
      newInstitutionStatus: null,
    }),
    [types.FIND_USERS_GET_REQUEST]: (state: ModuleTypeFoundUsers): ModuleTypeFoundUsers => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.FIND_USERS_GET_SUCCESS]: (
      state: ModuleTypeFoundUsers,
      { payload }: PayloadAction,
    ): ModuleTypeFoundUsers => ({
      ...state,
      foundUsers: payload.users,
    }),
    [types.FIND_USERS_GET_FAILURE]: (state: ModuleTypeFoundUsers): ModuleTypeFoundUsers => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.FIND_USERS_GET_CLEAN]: (state: ModuleTypeFoundUsers): ModuleTypeFoundUsers => ({
      ...state,
      hasErrMessage: false,
      foundUsers: null,
    }),
    [types.INSTITUTION_ASSOCIATIONS_GET_REQUEST]: (
      state: ModuleTypeAssociationsInformation,
    ): ModuleTypeAssociationsInformation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.INSTITUTION_ASSOCIATIONS_GET_SUCCESS]: (
      state: ModuleTypeAssociationsInformation,
      { payload }: PayloadAction,
    ): ModuleTypeAssociationsInformation => ({
      ...state,
      associationsInformation: payload,
    }),
    [types.INSTITUTION_ASSOCIATIONS_GET_FAILURE]: (
      state: ModuleTypeAssociationsInformation,
    ): ModuleTypeAssociationsInformation => ({
      ...state,
      hasErrMessage: true,
    }),
    [types.INSTITUTION_ASSOCIATIONS_GET_CLEAN]: (
      state: ModuleTypeAssociationsInformation,
    ): ModuleTypeAssociationsInformation => ({
      ...state,
      hasErrMessage: false,
      associationsInformation: null,
    }),
    [types.NEW_USER_ASSOCIATION_POST_REQUEST]: (
      state: ModuleTypeNewUserAssociation,
    ): ModuleTypeNewUserAssociation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.NEW_USER_ASSOCIATION_POST_SUCCESS]: (
      state: ModuleTypeNewUserAssociation,
      { payload }: PayloadAction,
    ): ModuleTypeNewUserAssociation => ({
      ...state,
      hasErrMessage: false,
      newUserAssociationSuccess: true,
      newUserAssociationStatus: payload,
    }),
    [types.NEW_USER_ASSOCIATION_POST_FAILURE]: (
      state: ModuleTypeNewUserAssociation,
      { payload }: PayloadAction,
    ): ModuleTypeNewUserAssociation => ({
      ...state,
      hasErrMessage: true,
      newUserAssociationSuccess: false,
      newUserAssociationStatus: payload,
    }),
    [types.NEW_USER_ASSOCIATION_POST_CLEAN]: (
      state: ModuleTypeNewUserAssociation,
    ): ModuleTypeNewUserAssociation => ({
      ...state,
      hasErrMessage: false,
      newUserAssociationSuccess: null,
      newUserAssociationStatus: null,
    }),

    [types.EDIT_USER_ASSOCIATION_POST_REQUEST]: (
      state: ModuleTypeEditUserAssociation,
    ): ModuleTypeEditUserAssociation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.EDIT_USER_ASSOCIATION_POST_SUCCESS]: (
      state: ModuleTypeEditUserAssociation,
      { payload }: PayloadAction,
    ): ModuleTypeEditUserAssociation => ({
      ...state,
      hasErrMessage: false,
      editUserAssociationSuccess: true,
      editUserAssociationStatus: payload,
    }),
    [types.EDIT_USER_ASSOCIATION_POST_FAILURE]: (
      state: ModuleTypeEditUserAssociation,
      { payload }: PayloadAction,
    ): ModuleTypeEditUserAssociation => ({
      ...state,
      hasErrMessage: true,
      editUserAssociationSuccess: false,
      editUserAssociationStatus: payload,
    }),
    [types.EDIT_USER_ASSOCIATION_POST_CLEAN]: (
      state: ModuleTypeEditUserAssociation,
    ): ModuleTypeEditUserAssociation => ({
      ...state,
      hasErrMessage: false,
      editUserAssociationSuccess: null,
      editUserAssociationStatus: null,
    }),
    [types.USER_ASSOCIATION_DELETE_REQUEST]: (
      state: ModuleTypeDeleteUserAssociation,
    ): ModuleTypeDeleteUserAssociation => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_ASSOCIATION_DELETE_SUCCESS]: (
      state: ModuleTypeDeleteUserAssociation,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteUserAssociation => ({
      ...state,
      hasErrMessage: false,
      deleteUserAssociationSuccess: true,
      deleteUserAssociationStatus: payload,
    }),
    [types.USER_ASSOCIATION_DELETE_FAILURE]: (
      state: ModuleTypeDeleteUserAssociation,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteUserAssociation => ({
      ...state,
      hasErrMessage: true,
      deleteUserAssociationSuccess: false,
      deleteUserAssociationStatus: payload,
    }),
    [types.USER_ASSOCIATION_DELETE_CLEAN]: (
      state: ModuleTypeDeleteUserAssociation,
    ): ModuleTypeDeleteUserAssociation => ({
      ...state,
      hasErrMessage: false,
      deleteUserAssociationSuccess: null,
      deleteUserAssociationStatus: null,
    }),
    [types.USER_DELETE_FROM_DB_REQUEST]: (
      state: ModuleTypeDeleteUserFromDb,
    ): ModuleTypeDeleteUserFromDb => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.USER_DELETE_FROM_DB_SUCCESS]: (
      state: ModuleTypeDeleteUserFromDb,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteUserFromDb => ({
      ...state,
      deleteUserFromDbSuccess: true,
      deleteUserFromDbStatus: payload,
    }),
    [types.USER_DELETE_FROM_DB_FAILURE]: (
      state: ModuleTypeDeleteUserFromDb,
      { payload }: PayloadAction,
    ): ModuleTypeDeleteUserFromDb => ({
      ...state,
      deleteUserFromDbSuccess: false,
      deleteUserFromDbStatus: payload,
      hasErrMessage: true,
    }),
    [types.USER_DELETE_FROM_DB_CLEAN]: (
      state: ModuleTypeDeleteUserFromDb,
    ): ModuleTypeDeleteUserFromDb => ({
      ...state,
      hasErrMessage: false,
      deleteUserFromDbSuccess: null,
      deleteUserFromDbStatus: null,
    }),
    [types.VERIFICATION_EMAIL_REQUEST]: (
      state: ModuleTypeVerificationEmail,
    ): ModuleTypeVerificationEmail => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.VERIFICATION_EMAIL_SUCCESS]: (
      state: ModuleTypeVerificationEmail,
      { payload }: PayloadAction,
    ): ModuleTypeVerificationEmail => ({
      ...state,
      verificationEmailSuccess: true,
      verificationEmailStatus: payload,
    }),
    [types.VERIFICATION_EMAIL_FAILURE]: (
      state: ModuleTypeVerificationEmail,
      { payload }: PayloadAction,
    ): ModuleTypeVerificationEmail => ({
      ...state,
      verificationEmailSuccess: false,
      verificationEmailStatus: payload,
      hasErrMessage: true,
    }),
    [types.VERIFICATION_EMAIL_CLEAN]: (
      state: ModuleTypeVerificationEmail,
    ): ModuleTypeVerificationEmail => ({
      ...state,
      hasErrMessage: false,
      verificationEmailSuccess: null,
      verificationEmailStatus: null,
    }),
    [types.UPDATE_INSTITUTION_DATA_REQUEST]: (
      state: ModuleTypeUpdateInstitutionData,
    ): ModuleTypeUpdateInstitutionData => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.UPDATE_INSTITUTION_DATA_SUCCESS]: (
      state: ModuleTypeUpdateInstitutionData,
      { payload }: PayloadAction,
    ): ModuleTypeUpdateInstitutionData => ({
      ...state,
      updateInstitutionDataSuccess: true,
      updateInstitutionDataStatus: payload,
    }),
    [types.UPDATE_INSTITUTION_DATA_FAILURE]: (
      state: ModuleTypeUpdateInstitutionData,
      { payload }: PayloadAction,
    ): ModuleTypeUpdateInstitutionData => ({
      ...state,
      updateInstitutionDataSuccess: false,
      updateInstitutionDataStatus: payload,
      hasErrMessage: true,
    }),
    [types.UPDATE_INSTITUTION_DATA_CLEAN]: (
      state: ModuleTypeUpdateInstitutionData,
    ): ModuleTypeUpdateInstitutionData => ({
      ...state,
      hasErrMessage: false,
      updateInstitutionDataSuccess: null,
      updateInstitutionDataStatus: null,
    }),
    [types.DOWNLOAD_USER_SHEET_POST_REQUEST]: (
      state: ModuleTypeDownloadUserSheet,
    ): ModuleTypeDownloadUserSheet => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.DOWNLOAD_INSTITUTION_SHEET_POST_REQUEST]: (
      state: ModuleTypeDownloadUserSheet,
    ): ModuleTypeDownloadUserSheet => ({
      ...state,
      hasErrMessage: false,
    }),
    [types.DOWNLOAD_USER_SHEET_POST_SUCCESS]: (
      state: ModuleTypeDownloadUserSheet,
      { payload }: PayloadAction,
    ): ModuleTypeDownloadUserSheet => ({
      ...state,
      downloadUserSheetSuccess: true,
      downloadUserSheetStatus: payload,
    }),
    [types.DOWNLOAD_USER_SHEET_POST_FAILURE]: (
      state: ModuleTypeDownloadUserSheet,
      { payload }: PayloadAction,
    ): ModuleTypeDownloadUserSheet => ({
      ...state,
      downloadUserSheetSuccess: false,
      downloadUserSheetStatus: payload,
      hasErrMessage: true,
    }),
    [types.DOWNLOAD_USER_SHEET_POST_CLEAN]: (
      state: ModuleTypeDownloadUserSheet,
    ): ModuleTypeDownloadUserSheet => ({
      ...state,
      hasErrMessage: false,
      downloadUserSheetSuccess: null,
      downloadUserSheetStatus: null,
    }),
  },
  {
    initialModuleStateRolesInformation,
    initialModuleStateUsersInformation,
    initialModuleNewUser,
    initialModuleDeleteUser,
    initialModuleEditUser,
    initialModuleActivateUser,
    initialModuleRequisitionsInformation,
    initialModuleHandleRequisition,
    initialModuleStateInstitutionsInformation,
    initialModuleNewInstitution,
    initialModuleStateFoundUsers,
    initialModuleStateAssociationsInformation,
    initialModuleNewUserAssociation,
    initialModuleEditUserAssociation,
    initialModuleDeleteUserAssociation,
    initialModuleDeleteUserFromDb,
    initialModuleVerificationEmail,
    initialModuleUpdateInstitutionData,
    initialModuleDownloadUserSheet,
  },
)

export default reducer
