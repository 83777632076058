import { colors } from '../../../../../utils/colors'

export const JobExperienceFormStyles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    width: '100%',
    rowGap: '10px',
    border: `1px solid ${colors.principal}`,
    borderRadius: '10px',
    padding: '30px',
  },
  closeButton: { alignSelf: 'flex-end', backgroundColor: colors.principal, color: 'white' },
  formControl: { width: '80%', margin: '0 auto' },
  saveButton: { backgroundColor: colors.principal, color: 'white', alignSelf: 'center' },
  popOverMobile: {
    fontSize: '16px',
    textTransform: 'initial',
    fontWeight: 'initial',
  },
}
