import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import Abm from './pages/abm'
import AdInfo from './pages/AdInfo'
import CandidateInfo from './pages/CandidateInfo'
import CandidateSearch from './pages/CandidateSearch'
import Change from './pages/password/change'
import Code from './pages/password/code'
import EmailVerification from './pages/email-verification'
import FavoritesHandler from './pages/FavoritesHandler'
import Home from './pages/home'
import Institution from './pages/profile/institution'
import InstitutionInfo from './pages/InstitutionInfo'
import InvitesHistory from './pages/InvitesHistory'
import JobAds from './pages/JobAds'
import JobApplicationsHandler from './pages/JobApplicationsHandler'
import JobApplicationsSearch from './pages/JobApplicationsSearch'
import { JwtData } from './state/modules/login/services/types'
import Landing from './pages/landing'
import Loader from './components/loader'
import Login from './pages/login'
import NewJobAd from './pages/NewJobAd'
import NoMatch from './pages/no-match'
import PasswordProfile from './pages/passwordprofile'
import ProtectedLayout from './layouts/ProtectedLayout'
import Register from './pages/register'
import RegisterInstitutionProfile from './pages/profile/register'
import RegisterIntitution from './pages/registerInstitution'
import Reset from './pages/password/reset'
import { Unsubscribe } from './pages/Unsubscribe'
import User from './pages/profile/user'
import { getRole } from './utils/profile'
import jwtDecode from 'jwt-decode'
import { userInformationRequest } from './state/modules/user/actions'

function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const token = localStorage.getItem('access_token')
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const [user, setUser] = useState<any>(token)
  const { isAdmin, isAIEPBA, isViewer } = getRole(
    userInformation?.Institutions,
    userInformation?.Role?.id,
  )

  const loaderStyles = {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  useEffect(() => {
    if (token !== null) {
      const jwtTokenData: JwtData = jwtDecode(token)
      dispatch(userInformationRequest({ userId: jwtTokenData.id.toString() }))
    }
  }, [token])

  useEffect(() => {
    setUser(token)
  }, [userInformation])

  useEffect(() => {
    const career: string | null = sessionStorage.getItem('career')

    if (
      career !== 'success_relocated' &&
      location.pathname.includes('/home') &&
      Boolean(career?.length) &&
      userInformation
    ) {
      navigate(`${career}`)
    }

    if (
      !career &&
      ['/careers/career', '/candidates/profile', '/home'].some((candidate) =>
        location.pathname.includes(candidate),
      )
    )
      sessionStorage.setItem('career', location.pathname)
  }, [location.pathname])

  return (
    <Routes>
      <Route path='/' index element={<Landing />} />
      <Route path='/institutional-landing' index element={<Landing />} />
      <Route path='/auth/callback' element={<Login />} />
      <Route path='/validate/email-verification' element={<EmailVerification />} />
      <Route path='/login' element={!user ? <Login /> : <Navigate to='/home' />} />
      <Route path='/register' element={<Register />} />
      <Route path='/registerinstitution' element={<RegisterIntitution />} />
      <Route path='/profile/registerinstitution' element={<RegisterInstitutionProfile />} />
      <Route path='/password/reset' element={<Reset />} />
      <Route path='/password/change' element={<Change />} />
      <Route path='/password/code' element={<Code />} />
      <Route element={<ProtectedLayout canActivate={user} redirectPath='/login' />}>
        <Route
          path='/home'
          element={userInformation ? <Home /> : <Loader props={loaderStyles} />}
        />
        <Route
          path='/passwordprofile'
          element={userInformation ? <PasswordProfile /> : <Loader props={loaderStyles} />}
        />
        <Route
          path='/careers/job-applications'
          element={userInformation ? <JobApplicationsSearch /> : <Loader props={loaderStyles} />}
        />
        <Route
          path='/careers/handle-applications'
          element={
            userInformation ? (
              isViewer || isAIEPBA ? (
                <JobApplicationsHandler />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />
        <Route
          path='/careers/career/:adId'
          element={userInformation ? <AdInfo /> : <Loader props={loaderStyles} />}
        />
        <Route path='/profile/user' element={<User />} />
        <Route
          path='/profile/institution/:id'
          element={
            userInformation ? (
              isViewer ? (
                <Institution />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />
        <Route
          path='/profile/admin/abm'
          element={
            userInformation ? isAIEPBA ? <Abm /> : <NoMatch /> : <Loader props={loaderStyles} />
          }
        />
        <Route
          path='/careers/new-oportunity'
          element={
            userInformation ? (
              isAdmin || isAIEPBA ? (
                <NewJobAd />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />
        <Route
          path='/careers/oportunities'
          element={
            userInformation ? (
              isViewer || isAIEPBA ? (
                <JobAds />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />
        <Route
          path='/candidates/search'
          element={
            userInformation ? (
              isViewer || isAIEPBA ? (
                <CandidateSearch />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />
        <Route
          path='/candidates/profile/:userId'
          element={
            userInformation ? (
              isViewer || isAIEPBA ? (
                <CandidateInfo />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />

        <Route
          path='/institution/detail/:institutionId'
          element={userInformation ? <InstitutionInfo /> : <Loader props={loaderStyles} />}
        />
        <Route
          path='/invites-history/:institutionId'
          element={
            userInformation ? (
              isAdmin ? (
                <InvitesHistory />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />
        <Route
          path='/favorites'
          element={
            userInformation ? (
              isViewer || isAIEPBA ? (
                <FavoritesHandler />
              ) : (
                <NoMatch />
              )
            ) : (
              <Loader props={loaderStyles} />
            )
          }
        />
      </Route>

      <Route path='/unsubscribe' element={<Unsubscribe />} />
      <Route path='*' element={<NoMatch />} />
    </Routes>
  )
}

export default App
