import InstitutionalCrew from '../../../../assets/images/landing/institutional-crew.png'
import { colors } from '../../../../utils/colors'
import { Image,Box, Button, useBreakpoint, useMediaQuery,Flex, VStack, Heading, Stack, Text  } from '@chakra-ui/react'
import ColorButton from '../../../buttons/button-blue'
import { Buttons } from '../../../header'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { LandingMarginProps } from '../../../../pages/landing/regular-landing'
import PropTypes from 'prop-types'
import { headerLandingStyles } from '../header-landing/styles'

const AreYouInstitution: React.FC<LandingMarginProps> = ({ contPadding }) => {
  const [isLogged, setIsLogged] = useState(false)
  const brkpnt = useBreakpoint('sm');
  const overlapPoint = brkpnt === 'base' || brkpnt === 'sm' || brkpnt === 'md' || brkpnt === 'lg'

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])
  const navigate = useNavigate()

  const handleGoToInstitutional = () => {
    navigate('/institutional-landing')
  }

  return (
    <Box
      display='flex'
      height={{ base: '250px', sm: '400px', md: '400px', lg: '450px', xl: '500px', '2xl': '530px' }}
      width={['100vw', '100vw']}
      alignItems={['center', 'center']}
      justifyContent={(overlapPoint) ? 'center' : 'flex-start'}
      position='relative'
      pt='2'
      pl={(overlapPoint) ? '0' : contPadding}
      bgImage={(overlapPoint) ? undefined : InstitutionalCrew}
      bgPosition={{ base: '100% bottom', sm: 'right bottom', md: 'right bottom', lg: '92% bottom', '2xl': '82% bottom' }}
      bgSize={{ base: 'cover', sm: 'cover', md: 'cover', lg: 'cover', xl: 'cover', '2xl': 'cover' }}
      bgRepeat='no-repeat'
      bgColor='#ebedf0'
    >
      <Box
        position={(overlapPoint) ? 'relative' : 'absolute'}
        top={(overlapPoint) ? 'unset' : ['15%', '16%']}
        left={(overlapPoint) ? 'unset' : ['0', '0']}
        display='flex'
        alignContent='center'
        justifyContent='center'
        flexDirection='column'
        pl={(overlapPoint) ? '0' : contPadding}
      >
        <Box
          mb={{ base: '10px', md: '15px', lg: '25px' }}
        >
          <Text
            fontSize={{ base: '2rem', sm: '48px', md: '48px', lg: '42px', xl: '55px', '2xl': '60px' }}
            textAlign={'left'}
            width={'100%'}
            color={colors.institutionalRed}
          >
            ¿Sos una institución?
          </Text>
        </Box>
        <Box
          width='100%'
          color='#004876'
          fontSize={{ base: '18px', sm: '20px', md: '20px', lg: '24px', xl: '32px', '2xl': '36px' }}
          mb={{ base: '18px', md: '18px', lg: '25px', '2xl': '38px' }}
        >
          Conocé todo lo que EduPrivada <br /> tiene para ofrecerte
        </Box>
        <Box
          color='#004876'
          fontSize={['18px', '18px']}
        >
          <Button
            sx={headerLandingStyles.actionButton}
            size={{ base: 'sm', md: 'md', lg: 'lg' }}
            onClick={handleGoToInstitutional}
            css={{
              '@media screen and (min-width: 1536px)': {
                width: '270px',
                height: '65px',
                fontSize: '30px',
                borderRadius: '10px'
              },
            }}
          >
            {Buttons.KNOW_MORE}</Button>
        </Box>
      </Box>
    </Box>
  )
}
AreYouInstitution.propTypes = {
  contPadding: PropTypes.string.isRequired,
}
export default AreYouInstitution
