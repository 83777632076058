import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Image,
  Text,
  Button,
  Progress,
  Switch,
  Icon,
  UseToastOptions,
  useToast,
  Tooltip,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useMediaQuery,
} from '@chakra-ui/react'
import { EditIcon, QuestionIcon } from '@chakra-ui/icons'
import ProfileEmpty from '../../../../assets/images/profile/ProfileEmpty.png'

import ModalResumeUser from '../informationResume/ModalResumeUser'
import ConfirmModal from '../../../confirmModal'
import ResumeModal from '../../../resumeModal'
import {
  privateModeClean,
  privateModeRequest,
  resumeDownloadClean,
  resumeDownloadRequest,
} from '../../../../state/modules/user/actions'

import { colors } from '../../../../utils/colors'
import { usePercentage } from '../../../../utils/hooks/usePercentage'

const InformationProfile: React.FC = () => {
  const message = `Por cuestiones de seguridad todos los datos considerados sensibles no son visibles para las instituciones dentro de EDUprivada, 
    a menos que se postule a una oferta de trabajo específica o autorice expresamente a una Institución para que acceda a su perfil completo. 
    Si opta por el MODO PÚBLICO, todo su perfil estará disponible para que lo vean las Instituciones.`

  const dispatch = useDispatch()

  const user = useSelector((state: any) => state.user)
  const userInformation = useSelector((state: any) => state?.user?.userInformation ?? '')

  const [imagen, setImagen] = useState()
  const [isOpen, setIsOpen] = useState(false)
  const [isLogged, setIsLogged] = useState(false)
  const [isResume, setIsResume] = useState<boolean>(false)
  const [isConfirm, setIsConfirm] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<number>(1)
  const [isChecked, setIsChecked] = useState<boolean>(userInformation?.Profile?.isPrivate ?? true)

  const toast = useToast()
  const percentage = usePercentage()
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const toastMessages: UseToastOptions[] = [
    {
      description: 'Perfil descargado correctamente',
      status: 'success',
      duration: 2000,
    },
    {
      description: 'Ocurrio un error',
      status: 'error',
      duration: 2000,
    },
  ]

  useEffect(() => {
    userInformation === undefined || userInformation === null || userInformation === ''
      ? null
      : setIsChecked(userInformation?.Profile?.isPrivate)
  }, [userInformation])

  useEffect(() => {
    if (userInformation && userInformation.UserFiles) {
      const imageObject = userInformation.UserFiles.findLast(
        (file: any) => file.filePath && file.fileType === 'AVATAR',
      )

      if (imageObject) {
        const imageFilePath = imageObject.filePath
        setImagen(imageFilePath)
      }
    }
  }, [userInformation])

  useEffect(() => {
    const session = localStorage.getItem('access_token')
    if (session !== null) setIsLogged(true)
  }, [isLogged])

  useEffect(() => {
    if (user?.downloadResumeSuccess && user?.userResume) {
      const link = document.createElement('a')
      link.href = user.userResume
      link.download = `CV_${userInformation?.Profile?.name}_${userInformation?.Profile?.lastName}.pdf`
      link.click()
      toast(toastMessages[0])

      dispatch(resumeDownloadClean())
    }

    if (user?.downloadResumeSuccess === false) {
      toast(toastMessages[1])
      dispatch(resumeDownloadClean())
    }
  }, [user])

  useEffect(() => {
    if (user?.privateModeSuccess && user?.privateModeSuccess !== null) {
      toast({
        description: user.privateMode?.message,
        status: 'success',
        duration: 2000,
      })
      dispatch(privateModeClean())
    }

    if (user?.privateModeSuccess === false) {
      toast({
        description: user.privateMode?.response?.data?.message,
        status: 'error',
        duration: 2000,
      })
      dispatch(privateModeClean())
    }
  }, [user.privateModeSuccess])

  const handleShowModal = () => {
    setIsOpen((prev) => !prev)
  }

  const handleShowConfirm = () => {
    setIsConfirm((prev) => !prev)
  }
  const handleShowResume = () => {
    setIsResume((prev) => !prev)
  }

  const handlePrivateChange = () => {
    setIsChecked((prev) => !prev)
    dispatch(privateModeRequest({ userId: userInformation?.Profile?.userId, private: !isChecked }))
  }

  const handleResumeDownload = (name: any) => {
    if (name === 'con foto')
      dispatch(
        resumeDownloadRequest({
          userId: userInformation?.Profile?.userId,
          photo: 'true',
        }),
      )
    else
      dispatch(
        resumeDownloadRequest({
          userId: userInformation?.Profile?.userId,
          photo: 'false',
        }),
      )
  }
  return (
    <Box width='100%' display={'flex'} flexDirection={'column'} justifyContent={'center'} p={7}>
      <ModalResumeUser
        isOpen={isOpen}
        onClose={handleShowModal}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />

      <Box
        width='125px'
        height='125px'
        borderRadius='50%'
        borderWidth='2px'
        borderColor='black'
        overflow='hidden'
        alignSelf={'center'}
        mb={3}
      >
        {imagen ? (
          <Image src={imagen} alt='imagen' objectFit='contain' width='100%' height='100%' />
        ) : (
          <Image
            src={ProfileEmpty}
            alt='ProfileEmpty'
            objectFit='contain'
            width='100%'
            height='100%'
          />
        )}
      </Box>

      <Text
        fontSize='xl'
        alignSelf={'center'}
        color={'#004876'}
        fontWeight='bold'
        whiteSpace={'nowrap'}
      >
        {userInformation?.Profile?.name ?? 'Mi nombre'}
      </Text>
      <Text
        fontSize='xl'
        marginBottom='20px'
        alignSelf={'center'}
        color={'#004876'}
        fontWeight='bold'
        whiteSpace={'nowrap'}
      >
        {userInformation?.Profile?.lastName ?? 'Mi Apellido'}
      </Text>

      <Button colorScheme='blue' marginBottom='20px' width={'full'} onClick={handleShowModal}>
        <Icon as={EditIcon} boxSize={6} color={'white'} mr={2} />
      </Button>

      <Text color={'#004876'} marginBottom='10px'>
        Perfil {percentage}% completado
      </Text>
      <Progress value={percentage} marginBottom='20px' />
      <Text
        cursor={'pointer'}
        textAlign={'center'}
        size={'sm'}
        fontWeight={'normal'}
        onClick={handleShowResume}
        borderColor='#004876'
        color='#004876'
        border='1px solid #004876'
        borderRadius={'5px'}
        p={2}
        marginTop={'25px'}
      >
        Descargar perfil en formato CV{' '}
      </Text>

      <ResumeModal
        isOpen={isResume}
        onClose={handleShowResume}
        onConfirm={handleResumeDownload}
        title='Descargar CV'
        text='¿Desea descargar su CV con o sin foto?'
        buttonOne='Con foto'
        buttonTwo='Sin foto'
      />

      <Box m={5} display={'flex'} alignItems={'center'} justifyContent={'center'} columnGap={2}>
        <Switch
          colorScheme={isChecked ? 'green' : 'gray'}
          isChecked={isChecked}
          onChange={() => {
            handleShowConfirm()
          }}
          size={'lg'}
        />
        <Text fontSize={'1xl'}>Modo Privado</Text>
        {isMobile ? (
          <Popover>
            <PopoverTrigger>
              <Button backgroundColor='transparent'>
                <QuestionIcon color={colors.principal} />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody
                sx={{ fontSize: '16px', textTransform: 'initial', fontWeight: 'initial' }}
              >
                {message}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        ) : (
          <Tooltip label={message}>
            <QuestionIcon boxSize={5} />
          </Tooltip>
        )}
      </Box>
      <ConfirmModal
        isOpen={isConfirm}
        onClose={handleShowConfirm}
        onConfirm={handlePrivateChange}
        text={'¿Estás seguro que deseás activar/desactivar el modo privado?'}
        title={'Modo privado'}
        buttonOne={'Confirmar'}
        buttonTwo={'Cancelar'}
      />
    </Box>
  )
}

export default InformationProfile
