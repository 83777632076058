import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, NavLink, useNavigate, useParams } from 'react-router-dom'

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Img,
  Link,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'

import {
  adDetailClean,
  adDetailRequest,
  jobApplicationClean,
  jobApplicationRequest,
} from '../../state/modules/ads/actions'

import Loader from '../loader'

import { AdDetailProps } from './_types'

import { adDetailStyles } from './styles'
import institutionEmpty from '../../assets/images/profile/InsitutionEmpty.png'

import Dialog from '../Dialog/Dialog'

import { colors } from '../../utils/colors'
import { transformDate } from '../../utils/jobAds'
import { usePercentage } from '../../utils/hooks/usePercentage'
import { CloseIcon } from '@chakra-ui/icons'

const AdDetail: FC<AdDetailProps> = () => {
  const toastDuration = 2000

  const { adId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const ads = useSelector((state: any) => state?.ads)
  const results = useSelector((state: any) => state?.ads?.adInformation)
  const userId = useSelector((state: any) => state?.user?.userInformation?.id)
  const adInformation = useSelector((state: any) => state?.ads?.adInformation?.ad)
  const jobApplications = useSelector((state: any) => state?.ads?.adInformation?.JobApplications)

  const toast = useToast()
  const percentage = usePercentage()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const [application, setApplication] = useState<any>(false)

  const handleApplicationEvent = async () =>
    await dispatch(
      jobApplicationRequest({
        adId: adInformation.id,
        token: localStorage.getItem('access_token'),
      }),
    )

  const handleApplication = () => {
    if (percentage === 100) {
      handleApplicationEvent()

      return
    }

    onOpen()
  }

  const handleEvent = (origin: string) => {
    onClose()

    return origin === 'goTo'
      ? navigate('/profile/user')
      : origin === 'close'
      ? handleApplicationEvent()
      : ''
  }

  useEffect(() => {
    dispatch(adDetailRequest({ adId }))
    return () => {
      dispatch(adDetailClean())
    }
  }, [adId, ads?.jobApplicationSuccess === true])

  useEffect(() => {
    if (userId && jobApplications?.length > 0) {
      const match = jobApplications.find((e: any) => e.userId == userId)
      setApplication(match ? match : false)
    }
  }, [jobApplications, userId])

  useEffect(() => {
    if (ads?.jobApplicationSuccess && ads?.jobApplicationSuccess !== null) {
      toast({
        description: ads?.jobApplicationStatus.message,
        status: 'success',
        duration: toastDuration,
      })

      dispatch(jobApplicationClean())
    }

    if (ads?.jobApplicationSuccess === false) {
      toast({
        description: ads?.jobApplicationStatus.response?.data?.message,
        status: 'error',
        duration: toastDuration,
      })

      dispatch(jobApplicationClean())
    }
  }, [ads?.jobApplicationSuccess])

  useEffect(() => {
    return () => {
      sessionStorage.setItem('career', 'success_relocated')
    }
  }, [])

  return (
    <>
      {results === false ? (
        <Navigate to={'/home'} />
      ) : adInformation ? (
        <Box id='adDetail' sx={adDetailStyles.container}>
          {/* MAIN CONTAINER */}

          {/* BLUE STRIPE */}
          <Box sx={isMobile ? adDetailStyles.topStripeMobile : adDetailStyles.topStripe}>
            <Box position='relative' pl={['40px', '68px', '68px']}>
              <Box position='absolute' top={['6px', '10px', '8px']}>
                <Breadcrumb
                  color='#fff'
                  fontSize={['13px', '14px', '19px']}
                  fontWeight={100}
                  fontFamily={'Roboto-Flex'}
                >
                  <BreadcrumbItem>
                    <BreadcrumbLink href='/home'>Avisos</BreadcrumbLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <BreadcrumbLink href='#'>Aviso</BreadcrumbLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Box>

              {/* AVATAR IMG AND BREADCRUM CONTAINER */}
              <Box
                sx={
                  isMobile ? adDetailStyles.avatarContainerMobile : adDetailStyles.avatarContainer
                }
                boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
                position='absolute'
                top={['42px', '62px', '62px']}
              >
                <NavLink
                  key={adInformation?.Institutions[0]?.id}
                  target='_blank'
                  to={`/institution/detail/${adInformation?.Institutions[0]?.id}`}
                >
                  <Img
                    sx={isMobile ? adDetailStyles.avatarMobile : adDetailStyles.avatarImage}
                    alt={adInformation?.Institutions[0]?.name}
                    src={
                      adInformation?.showInstitutionName !== false
                        ? adInformation?.Institutions[0]?.UserFiles[0]?.filePath
                          ? `${adInformation?.Institutions[0]?.UserFiles[0]?.filePath}`
                          : institutionEmpty
                        : institutionEmpty
                    }
                  />
                </NavLink>
              </Box>
            </Box>

            {/* CONDITIONAL POSTULATE BUTTON (ONLY VISIBLE IN MOBILE MODE) */}
            {isMobile && (
              <Box position='absolute' right='0' top='50%' mr='20px'>
                {!application ? (
                  <Button
                    onClick={handleApplication}
                    sx={{ ...adDetailStyles.actionButton }}
                    float='right'
                    _hover={{ bg: colors.institutionalRed }}
                    size={'sm'}
                  >
                    Postular
                  </Button>
                ) : (
                  <Text sx={adDetailStyles.appliedJobMobile}>
                    Postulado el{' '}
                    {`${new Date(application?.createdAt)
                      ?.toISOString()
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .join('-')}`}
                  </Text>
                )}
              </Box>
            )}
          </Box>

          {/* HEADING AND BODY CONTAINER */}
          <Box mt={['10px', '50px', '20px']} p={['0 20px', '0 68px', '0 68px']}>
            {/* TITLE AND "VACANTES DISPONIBLES" CONTAINER */}
            <Box
              display='flex'
              flexDirection={['column', 'row']}
              w='100%'
              justifyContent='space-between'
              alignItems='baseline'
              pb='5px'
            >
              <Box w={['100%', 'unset']}>
                <Text sx={adDetailStyles.heading} fontSize={['25px', '20px', '40px', '40px']}>
                  {adInformation?.title}
                </Text>
              </Box>
              <Box w={['100%', 'unset']}>
                <Text sx={adDetailStyles.subHeading} fontSize={['18px', '18px', '20px', '21px']}>
                  Vacantes disponibles:&nbsp;
                  {adInformation?.vacancyAvailable && adInformation?.vacancyAvailable !== null
                    ? adInformation?.vacancyAvailable > 1
                      ? `${adInformation?.vacancyAvailable}`
                      : `${adInformation?.vacancyAvailable}`
                    : '-'}
                </Text>
              </Box>
            </Box>

            <Box display='flex' flexDirection={['column', 'row']} w='100%' mb='50px'>
              <Box width={['100%', '70%', '70%', '70%']}>
                {' '}
                {adInformation?.Institutions?.length > 0
                  ? adInformation?.Institutions?.map((institution: any) => {
                      return adInformation?.showInstitutionName ? (
                        <ul key={institution.id}>
                          <li style={{ listStyle: 'none' }}>
                            <NavLink
                              key={institution.id}
                              target='_blank'
                              to={`/institution/detail/${institution.id}`}
                              style={{
                                textTransform: 'capitalize',
                                marginLeft: '5px',
                              }}
                            >
                              <Link sx={adDetailStyles.institutionName}>{institution.name}</Link>
                            </NavLink>
                          </li>
                        </ul>
                      ) : (
                        <Text key={institution.id} sx={adDetailStyles.institutionName}>
                          {adInformation?.Institutions?.length > 1 && '*'} Institución educativa
                        </Text>
                      )
                    })
                  : ''}
              </Box>

              {!isMobile && (
                <Box w={['15%', '30%', '30%', '30%']}>
                  {!application ? (
                    <Button
                      onClick={handleApplication}
                      sx={adDetailStyles.actionButton}
                      float='right'
                      _hover={{ bg: colors.institutionalRed }}
                    >
                      Postular
                    </Button>
                  ) : (
                    <Text sx={adDetailStyles.appliedJob}>
                      Postulado el{' '}
                      {`${new Date(application?.createdAt)
                        ?.toISOString()
                        .split('T')[0]
                        .split('-')
                        .reverse()
                        .join('-')}`}
                    </Text>
                  )}
                </Box>
              )}
            </Box>
            <Box></Box>
            <Box></Box>

            {/* BODY CONTAINER */}
            <Box mt={['0', '50px', '50px']}>
              <Card
                boxShadow='10px 10px 40px 0.5px rgb(0 0 0 / 10%)'
                borderRadius='12px'
                p={['20px', '40px 68px']}
              >
                <CardHeader p='0'>
                  <Heading sx={adDetailStyles.bodyTitle}>Datos del aviso</Heading>
                </CardHeader>

                <CardBody p='0' mt='30px'>
                  {/* BODY TITLE */}

                  {/* FILA 1 */}
                  <Stack direction={['column', 'row']} p='0'>
                    {/* NIVEL */}
                    <Box
                      w={['100%', '33%', '33%']}
                      display='flex'
                      flexDirection={
                        adInformation?.Levels?.length === 1
                          ? 'row'
                          : adInformation?.Levels?.length > 1
                          ? 'column'
                          : 'row'
                      }
                    >
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Nivel:&nbsp;</Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.Levels?.length > 0
                          ? adInformation?.Levels?.map((level: any) => (
                              <Text
                                key={level.id}
                                sx={{
                                  textTransform: 'capitalize',
                                  ...adDetailStyles.adDataItemText,
                                }}
                              >
                                {level.description}
                              </Text>
                            ))
                          : '-'}
                      </Text>
                    </Box>

                    {/* MODALIDAD */}
                    <Box
                      w={['100%', '33%', '33%']}
                      display='flex'
                      flexDirection={
                        adInformation?.InstitutionTypes?.length === 1
                          ? 'row'
                          : adInformation?.InstitutionTypes?.length > 1
                          ? 'column'
                          : 'row'
                      }
                    >
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Modalidad:&nbsp;</Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.InstitutionTypes?.length > 0
                          ? adInformation?.InstitutionTypes?.map((institutionType: any) => (
                              <Text
                                key={institutionType.id}
                                sx={{
                                  textTransform: 'capitalize',
                                  ...adDetailStyles.adDataItemText,
                                }}
                              >
                                {`${institutionType?.description.toLowerCase()}`}
                              </Text>
                            ))
                          : '-'}
                      </Text>
                    </Box>

                    <Box w={['100%', '33%', '33%']}>
                      {/* Provincia - Depto/Partido - Localidad */}
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Provincia - Depto/Partido - Localidad:
                      </Text>
                      {adInformation?.Zones?.length > 0
                        ? adInformation?.Zones?.map((zone: any) => (
                            <Text
                              key={zone.id}
                              sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}
                            >
                              {`${zone?.Province?.description.toLowerCase()} - ${zone?.Department?.description.toLowerCase()} - ${zone?.Locality?.description.toLowerCase()}`}
                            </Text>
                          ))
                        : '-'}
                    </Box>
                  </Stack>

                  <HStack my='40px'>
                    <Divider />
                  </HStack>

                  {/* FILA 3*/}

                  <Stack direction={['column', 'row']}>
                    {/* TIPO DE CARGO */}
                    <Box w={['100%', '33%', '33%']} display='flex'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Tipo de cargo:&nbsp;</Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.Position?.type && adInformation?.Position?.type !== null
                          ? adInformation?.Position?.type.toLowerCase()
                          : '-'}
                      </Text>
                    </Box>

                    {/* PUESTO DE TRABAJO */}
                    <Box
                      w={['100%', '33%', '33%']}
                      display='flex'
                      flexDirection='row'
                      flexWrap='wrap'
                    >
                      <Box w='fit-content'>
                        <Text sx={adDetailStyles.adDataItemReferenceText}>
                          Puesto de trabajo:&nbsp;
                        </Text>
                      </Box>
                      <Box w='fit-content'>
                        {adInformation?.Position?.description &&
                        adInformation?.Position?.description !== null
                          ? adInformation?.Position?.description
                          : ''}
                      </Box>
                    </Box>
                    <Box w={['100%', '33%', '33%']} display='flex'>
                      {/* MODALIDAD DE TRABAJO */}
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Modalidad de trabajo:&nbsp;
                      </Text>
                      {adInformation?.JobType?.description &&
                      adInformation?.JobType?.description !== null
                        ? adInformation?.JobType?.description
                        : '-'}
                    </Box>
                  </Stack>

                  <HStack my='40px'>
                    <Divider />
                  </HStack>

                  <Stack direction={['column', 'row']}>
                    {/* TIPO DE AREA */}
                    <Box w={['100%', '33%', '33%']} display='flex'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Tipo de área:&nbsp;</Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.Subject?.type && adInformation?.Subject?.type !== null
                          ? `${adInformation?.Subject?.type.toLowerCase()}`
                          : '-'}
                      </Text>
                    </Box>

                    {/* ÁREA */}
                    <Box w={['100%', '33%', '33%']} display='flex'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Área:&nbsp;</Text>
                      {adInformation?.Subject?.description &&
                      adInformation?.Subject?.description !== null
                        ? `${adInformation?.Subject?.description}`
                        : '-'}
                    </Box>

                    {/* SUPLENCIAS */}
                    <Box
                      w={['100%', '33%', '33%']}
                      display='flex'
                      flexDirection={
                        adInformation?.Suplencies?.length === 1
                          ? 'row'
                          : adInformation?.Suplencies.length > 1
                          ? 'column'
                          : 'row'
                      }
                    >
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Suplencias:&nbsp;</Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.Suplencies?.length > 0
                          ? adInformation?.Suplencies?.map((suplency: any) => (
                              <Text
                                key={suplency.id}
                                sx={{
                                  textTransform: 'capitalize',
                                  ...adDetailStyles.adDataItemText,
                                }}
                              >
                                {suplency.description}
                              </Text>
                            ))
                          : '-'}
                      </Text>
                    </Box>
                  </Stack>

                  <HStack my='40px'>
                    <Divider />
                  </HStack>

                  {/* FILA 4 */}
                  <Stack direction={['column', 'row']}>
                    {/* FRANJA HORARIA */}
                    <Box
                      w={['100%', '33%', '33%']}
                      display='flex'
                      flexDirection={
                        adInformation?.TimeSlots.length === 1
                          ? 'row'
                          : adInformation?.TimeSlots.length > 1
                          ? 'column'
                          : 'row'
                      }
                    >
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Franja horaria:&nbsp;</Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.TimeSlots?.length > 0
                          ? adInformation?.TimeSlots?.map((shift: any) => (
                              <Text
                                key={shift.id}
                                sx={{
                                  textTransform: 'capitalize',
                                  ...adDetailStyles.adDataItemText,
                                }}
                              >
                                {shift.description}
                              </Text>
                            ))
                          : '-'}
                      </Text>
                    </Box>

                    {/* DISPONIBILIDAD */}
                    <Box
                      w={['100%', '33%', '33%']}
                      display='flex'
                      flexDirection={
                        adInformation?.AdTimeAvailabilities?.length === 1
                          ? 'row'
                          : adInformation?.AdTimeAvailabilities.length > 1
                          ? 'column'
                          : 'row'
                      }
                    >
                      <Text sx={adDetailStyles.adDataItemReferenceText}>Disponibilidad:&nbsp;</Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.TimeSlots?.length > 0
                          ? adInformation?.AdTimeAvailabilities?.map((time: any) => (
                              <Text
                                key={time.id}
                                sx={{
                                  textTransform: 'capitalize',
                                  ...adDetailStyles.adDataItemText,
                                }}
                              >
                                {time.day}
                              </Text>
                            ))
                          : '-'}
                      </Text>
                    </Box>

                    <Box w={['100%', '33%', '33%']} display='flex'>
                      {/* CANTIDAD DE HORAS */}
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Cantidad de horas:&nbsp;
                      </Text>
                      {adInformation?.amountofHours && adInformation?.amountofHours !== null
                        ? adInformation?.amountofHours > 1
                          ? `${adInformation?.amountofHours} horas`
                          : `${adInformation?.amountofHours} hora`
                        : '-'}
                    </Box>
                  </Stack>

                  <HStack my='40px'>
                    <Divider />
                  </HStack>

                  {/* FILA 5 */}
                  <Stack direction={['column', 'row']}>
                    {/* TAREAS REQUERIDAS */}
                    <Box w='100%'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Tareas requeridas:&nbsp;
                      </Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.requiredTask && adInformation?.requiredTask !== null
                          ? adInformation?.requiredTask
                          : '-'}
                      </Text>
                    </Box>
                  </Stack>

                  {/* FILA 6 */}
                  <Stack direction={['column', 'row']} mt='40px'>
                    {/* HABILIDADES BLANDAS */}
                    <Box w='100%'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Habilidades blandas:&nbsp;
                      </Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.softSkills && adInformation?.softSkills !== ''
                          ? adInformation?.softSkills
                          : ''}
                      </Text>
                    </Box>
                  </Stack>

                  {/* FILA 7 */}
                  <Stack direction={['column', 'row']} mt='40px'>
                    {/* EXPERIENCIA LABORAL SOLICITADA */}
                    <Box w='100%'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Experiencia laboral solicitada:&nbsp;
                      </Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.workExperience === '' ? '' : adInformation?.workExperience}
                      </Text>
                    </Box>
                  </Stack>

                  <HStack my='40px'>
                    <Divider />
                  </HStack>

                  {/* FILA 8  (ÚLTIMA) */}
                  <Stack direction={['column', 'row']} mt='40px'>
                    <Box w={['100%', '33%', '33%']} display='flex'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Nro de referencia:&nbsp;
                      </Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.referenceNumber &&
                        adInformation?.referenceNumber !== null &&
                        adInformation?.referenceNumber !== ''
                          ? adInformation?.referenceNumber
                          : '-'}
                      </Text>
                    </Box>
                    <Box w={['100%', '33%', '33%']} display='flex'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Fecha de publicación:&nbsp;
                      </Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.createdAt
                          ? `${transformDate(adInformation?.publicationDate)}`
                          : ''}
                      </Text>
                    </Box>
                    <Box w={['100%', '33%', '33%']} display='flex'>
                      <Text sx={adDetailStyles.adDataItemReferenceText}>
                        Fecha de vencimiento:&nbsp;
                      </Text>
                      <Text sx={{ textTransform: 'capitalize', ...adDetailStyles.adDataItemText }}>
                        {adInformation?.createdAt
                          ? `${transformDate(adInformation?.expirationDate)}`
                          : '-'}
                      </Text>
                    </Box>
                  </Stack>
                </CardBody>
              </Card>
            </Box>
          </Box>
          <Dialog
            isOpen={isOpen}
            colorScheme='blue'
            eventLabel='Completa tu perfil'
            secondaryEventLabel='Postularse'
            onClose={() => handleEvent('close')}
            handleEvent={() => handleEvent('goTo')}
          >
            <>
              <Dialog.Header>
                <Box display='flex' alignItems='center' justifyContent='space-between' w='100%'>
                  <Text>¡Completá tu perfil!</Text>
                  <CloseIcon cursor='pointer' onClick={() => handleEvent('iconClose')} />
                </Box>
              </Dialog.Header>
              <Dialog.Body>
                <Text>
                  ¡Aumenta tus posibilidades de ser encontrado y accede a nuevas oportunidades!
                </Text>
              </Dialog.Body>
            </>
          </Dialog>
        </Box>
      ) : (
        <Loader props={adDetailStyles.loaderStyles} />
      )}
    </>
  )
}

export default AdDetail
