import * as types from './types'

import { abm } from './_ducks'

export const rolesRequest = abm.createAction(types.ROLES_GET_REQUEST)
export const rolesSuccess = abm.createAction(types.ROLES_GET_SUCCESS)
export const rolesFailure = abm.createAction(types.ROLES_GET_FAILURE)
export const rolesClean = abm.createAction(types.ROLES_GET_CLEAN)

export const usersRequest = abm.createAction(types.USERS_POST_REQUEST)
export const usersSuccess = abm.createAction(types.USERS_POST_SUCCESS)
export const usersFailure = abm.createAction(types.USERS_POST_FAILURE)
export const usersClean = abm.createAction(types.USERS_POST_CLEAN)

export const newUserRequest = abm.createAction(types.NEW_USER_POST_REQUEST)
export const newUserSuccess = abm.createAction(types.NEW_USER_POST_SUCCESS)
export const newUserFailure = abm.createAction(types.NEW_USER_POST_FAILURE)
export const newUserClean = abm.createAction(types.NEW_USER_POST_CLEAN)

export const deleteUserRequest = abm.createAction(types.USER_DELETE_REQUEST)
export const deleteUserSuccess = abm.createAction(types.USER_DELETE_SUCCESS)
export const deleteUserFailure = abm.createAction(types.USER_DELETE_FAILURE)
export const deleteUserClean = abm.createAction(types.USER_DELETE_CLEAN)

export const editUserRequest = abm.createAction(types.USER_EDIT_REQUEST)
export const editUserSuccess = abm.createAction(types.USER_EDIT_SUCCESS)
export const editUserFailure = abm.createAction(types.USER_EDIT_FAILURE)
export const editUserClean = abm.createAction(types.USER_EDIT_CLEAN)

export const activateUserRequest = abm.createAction(types.USER_ACTIVATE_REQUEST)
export const activateUserSuccess = abm.createAction(types.USER_ACTIVATE_SUCCESS)
export const activateUserFailure = abm.createAction(types.USER_ACTIVATE_FAILURE)
export const activateUserClean = abm.createAction(types.USER_ACTIVATE_CLEAN)

export const requisitionsRequest = abm.createAction(types.REQUISITIONS_GET_REQUEST)
export const requisitionsSuccess = abm.createAction(types.REQUISITIONS_GET_SUCCESS)
export const requisitionsFailure = abm.createAction(types.REQUISITIONS_GET_FAILURE)
export const requisitionsClean = abm.createAction(types.REQUISITIONS_GET_CLEAN)

export const handleRequisitionRequest = abm.createAction(types.REQUISITION_HANDLE_PUT_REQUEST)
export const handleRequisitionSuccess = abm.createAction(types.REQUISITION_HANDLE_PUT_SUCCESS)
export const handleRequisitionFailure = abm.createAction(types.REQUISITION_HANDLE_PUT_FAILURE)
export const handleRequisitionClean = abm.createAction(types.REQUISITION_HANDLE_PUT_CLEAN)

export const institutionsRequest = abm.createAction(types.INSTITUTIONS_POST_REQUEST)
export const institutionsSuccess = abm.createAction(types.INSTITUTIONS_POST_SUCCESS)
export const institutionsFailure = abm.createAction(types.INSTITUTIONS_POST_FAILURE)
export const institutionsClean = abm.createAction(types.INSTITUTIONS_POST_CLEAN)

export const newInstitutionRequest = abm.createAction(types.NEW_INSTITUTION_POST_REQUEST)
export const newInstitutionSuccess = abm.createAction(types.NEW_INSTITUTION_POST_SUCCESS)
export const newInstitutionFailure = abm.createAction(types.NEW_INSTITUTION_POST_FAILURE)
export const newInstitutionClean = abm.createAction(types.NEW_INSTITUTION_POST_CLEAN)

export const findUsersRequest = abm.createAction(types.FIND_USERS_GET_REQUEST)
export const findUsersSuccess = abm.createAction(types.FIND_USERS_GET_SUCCESS)
export const findUsersFailure = abm.createAction(types.FIND_USERS_GET_FAILURE)
export const findUsersClean = abm.createAction(types.FIND_USERS_GET_CLEAN)

export const institutionAssociationsRequest = abm.createAction(
  types.INSTITUTION_ASSOCIATIONS_GET_REQUEST,
)
export const institutionAssociationsSuccess = abm.createAction(
  types.INSTITUTION_ASSOCIATIONS_GET_SUCCESS,
)
export const institutionAssociationsFailure = abm.createAction(
  types.INSTITUTION_ASSOCIATIONS_GET_FAILURE,
)
export const institutionAssociationsClean = abm.createAction(
  types.INSTITUTION_ASSOCIATIONS_GET_CLEAN,
)

export const newUserAssociationRequest = abm.createAction(types.NEW_USER_ASSOCIATION_POST_REQUEST)
export const newUserAssociationSuccess = abm.createAction(types.NEW_USER_ASSOCIATION_POST_SUCCESS)
export const newUserAssociationFailure = abm.createAction(types.NEW_USER_ASSOCIATION_POST_FAILURE)
export const newUserAssociationClean = abm.createAction(types.NEW_USER_ASSOCIATION_POST_CLEAN)

export const editUserAssociationRequest = abm.createAction(types.EDIT_USER_ASSOCIATION_POST_REQUEST)
export const editUserAssociationSuccess = abm.createAction(types.EDIT_USER_ASSOCIATION_POST_SUCCESS)
export const editUserAssociationFailure = abm.createAction(types.EDIT_USER_ASSOCIATION_POST_FAILURE)
export const editUserAssociationClean = abm.createAction(types.EDIT_USER_ASSOCIATION_POST_CLEAN)

export const deleteUserAssociationRequest = abm.createAction(types.USER_ASSOCIATION_DELETE_REQUEST)
export const deleteUserAssociationSuccess = abm.createAction(types.USER_ASSOCIATION_DELETE_SUCCESS)
export const deleteUserAssociationFailure = abm.createAction(types.USER_ASSOCIATION_DELETE_FAILURE)
export const deleteUserAssociationClean = abm.createAction(types.USER_ASSOCIATION_DELETE_CLEAN)

export const deleteUserFromDbRequest = abm.createAction(types.USER_DELETE_FROM_DB_REQUEST)
export const deleteUserFromDbSuccess = abm.createAction(types.USER_DELETE_FROM_DB_SUCCESS)
export const deleteUserFromDbFailure = abm.createAction(types.USER_DELETE_FROM_DB_FAILURE)
export const deleteUserFromDbClean = abm.createAction(types.USER_DELETE_FROM_DB_CLEAN)

export const verificationMailRequest = abm.createAction(types.VERIFICATION_EMAIL_REQUEST)
export const verificationMailSuccess = abm.createAction(types.VERIFICATION_EMAIL_SUCCESS)
export const verificationMailFailure = abm.createAction(types.VERIFICATION_EMAIL_FAILURE)
export const verificationMailClean = abm.createAction(types.VERIFICATION_EMAIL_CLEAN)

export const updateInstitutionDataRequest = abm.createAction(types.UPDATE_INSTITUTION_DATA_REQUEST)
export const updateInstitutionDataSuccess = abm.createAction(types.UPDATE_INSTITUTION_DATA_SUCCESS)
export const updateInstitutionDataFailure = abm.createAction(types.UPDATE_INSTITUTION_DATA_FAILURE)
export const updateInstitutionDataClean = abm.createAction(types.UPDATE_INSTITUTION_DATA_CLEAN)

export const downloadUserSheetRequest = abm.createAction(types.DOWNLOAD_USER_SHEET_POST_REQUEST)
export const downloadInstitutionSheetRequest = abm.createAction(
  types.DOWNLOAD_INSTITUTION_SHEET_POST_REQUEST,
)
export const downloadUserSheetSuccess = abm.createAction(types.DOWNLOAD_USER_SHEET_POST_SUCCESS)
export const downloadUserSheetFailure = abm.createAction(types.DOWNLOAD_USER_SHEET_POST_FAILURE)
export const downloadUserSheetClean = abm.createAction(types.DOWNLOAD_USER_SHEET_POST_CLEAN)
