import * as Yup from 'yup'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Tooltip,
  UseToastOptions,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react'
import { DomicilioInstitutionForm, PostInstitutionForm } from '../_typesinstitution'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { FormInsitutionalData, modality } from './_types'
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  departmentsClean,
  departmentsRequest,
  employeesQuantityClean,
  employeesQuantityRequest,
  institutionTypesClean,
  institutionTypesRequest,
  institutionalDataClean,
  institutionalDataRequest,
  levelmodalitiesClean,
  levelmodalitiesRequest,
  levelsClean,
  levelsRequest,
  localitiesClean,
  localitiesRequest,
  provincesClean,
  provincesRequest,
  regionsClean,
  regionsRequest,
  studentsQuantityClean,
  studentsQuantityRequest,
  userInformationRequest,
} from '../../../../state/modules/user/actions'
import { formatLocalityId, formatProvinceId } from '../../../../utils/profile'
import { useDispatch, useSelector } from 'react-redux'

import { InfoIcon } from '@chakra-ui/icons'
import ReactSelect from 'react-select'
import { useParams } from 'react-router-dom'

interface Provincia {
  description: string
  id: string
}

interface Department {
  id: string
  description: string
}

interface Locality {
  id: string
  description: string
}

interface Region {
  id: string
  description: string
}

export const InitialLinks = ['Inicio', 'Mi Perfil']

const toastMessages: UseToastOptions[] = [
  {
    description: 'Cambios guardados correctamente',
    status: 'success',
    duration: 2000,
  },
  {
    description: 'Ocurrio un error',
    status: 'error',
    duration: 2000,
  },
]
const textoToolkit = `Número generado automáticamente por EDUprivada,
                            para cambiarlo por el número definitivo 
                            por favor contactarse con un Administrador de AIEPA.`

const InsitutionData = () => {
  const { id } = useParams()
  const toast = useToast()
  const dispatch = useDispatch()
  const [isMobile] = useMediaQuery('(max-width: 600px)')

  const state = useSelector((state: any) => state)
  const userInformation = useSelector((state: any) => state.user.userInformation ?? '')
  const institutoFilter = userInformation?.Institutions.filter((item: any) => item.id === id)

  const provincias: Provincia[] = useSelector((state: any) =>
    state?.user?.provincesList?.sort((a: any, b: any) =>
      a.description?.localeCompare(b.description),
    ),
  )
  const [selectedProvince, setSelectedProvince] = useState<string>(
    institutoFilter[0]?.Address?.Province?.id ?? '',
  )
  const departamentos: Department[] = useSelector((state: any) =>
    state?.user?.departmentsList?.sort(
      (a: any, b: any) => a.description?.split(' ')[1] - b.description?.split(' ')[1],
    ),
  )
  const [selectedDepartment, setSelectedDepartment] = useState<string>(
    institutoFilter[0]?.Address?.Department?.id ?? '',
  )
  const localidades: Locality[] = useSelector((state: any) =>
    state?.user?.localitiesList?.sort((a: any, b: any) =>
      a.description?.localeCompare(b.description),
    ),
  )
  const [selectedLocality, setSelectedLocality] = useState<string>(
    institutoFilter[0]?.Address?.Locality?.id ?? '',
  )
  const regiones: Region[] = useSelector((state: any) => state?.user?.regionsList)

  const alumnos: any[] = state?.user?.studentsQuantityList ?? []
  const empleados: any[] = state?.user?.employeesQuantityList ?? []
  const educationsLevels: any[] = state?.user?.levelsList ?? []
  const institutionTypes: any[] = state?.user?.institutionTypesList ?? []
  const levelModalities: any[] = state?.user?.levelModalitiesList ?? []
  const [selectedLevels, setSelectedLevels] = useState<string>(institutoFilter[0]?.levelId ?? '')
  const [array, setArray] = useState<any[]>([])
  const { institutionalDataSuccess } = useSelector((state: any) => state?.user)
  const initialModalityValues = institutoFilter[0]?.LevelModalities.map((e: any) => e.id)
  const [modality, setModality] = useState<any[]>(
    institutoFilter[0]?.LevelModalities?.length > 0
      ? institutoFilter[0]?.LevelModalities.map((e: any) => {
          return { value: e?.Modality?.id, label: e?.Modality?.description }
        })
      : [],
  )

  useEffect(() => {
    if (institutionalDataSuccess !== null && institutionalDataSuccess !== undefined) {
      toast(toastMessages[institutionalDataSuccess ? 0 : 1])
      institutionalDataSuccess && window.location.reload()

      return () => {
        dispatch(institutionalDataClean())
      }
    }
  }, [institutionalDataSuccess])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    dispatch(provincesRequest({ token }))
    dispatch(studentsQuantityRequest({ token }))
    dispatch(employeesQuantityRequest({ token }))
    dispatch(levelsRequest({ token }))
    dispatch(institutionTypesRequest({ token }))
    dispatch(levelmodalitiesRequest({ token }))

    return () => {
      dispatch(provincesClean())
      dispatch(studentsQuantityClean())
      dispatch(employeesQuantityClean())
      dispatch(levelsClean())
      dispatch(institutionTypesClean())
      dispatch(levelmodalitiesClean())
    }
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (selectedProvince !== '' && selectedProvince !== undefined && selectedProvince !== null)
      dispatch(departmentsRequest({ token, provinceId: selectedProvince }))

    return () => {
      dispatch(departmentsClean())
    }
  }, [selectedProvince])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (
      selectedDepartment !== '' &&
      selectedDepartment !== undefined &&
      selectedDepartment !== null
    )
      dispatch(
        localitiesRequest({
          token,
          departmentId: selectedDepartment,
        }),
      )
    return () => {
      dispatch(localitiesClean())
    }
  }, [selectedDepartment])

  useEffect(() => {
    const token = localStorage.getItem('access_token') ?? ''
    if (selectedProvince == '1') {
      dispatch(regionsRequest({ token, provinceId: selectedProvince }))
    }

    return () => {
      dispatch(regionsClean())
    }
  }, [selectedProvince])

  const handleProvinceChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedProvinceId = e.target.value
    setSelectedProvince(selectedProvinceId)
    setFieldValue('province', selectedProvinceId)
    setFieldValue('department', '')
    setSelectedDepartment('')
    setFieldValue('locality', '')
    setSelectedLocality('')
    setFieldValue('region', '')
  }

  const handleDepartmentChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedDepartmentId = e.target.value
    setSelectedDepartment(selectedDepartmentId)
    setFieldValue('department', selectedDepartmentId)
    setFieldValue('locality', '')
    setSelectedLocality('')
  }
  const handleLocalityChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedLocalityId = e.target.value
    setSelectedLocality(selectedLocalityId)
    setFieldValue('locality', selectedLocalityId)
  }

  const handleLevelsChange = (e: ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const selectedLevelId = e.target.value
    setSelectedLevels(selectedLevelId)
    setFieldValue('level', selectedLevelId)

    const filteredModalities = levelModalities.flatMap((md) =>
      md.levelid === selectedLevelId ? md : [],
    )
    setArray(filteredModalities)
    setModality([])
  }

  const handleModalityChange = (value: any, setFieldValue: any) => {
    setModality(value)
    const selectedOptions = value.map((option: any) => ({
      levelModalityId: option.value,
    }))
    setFieldValue('modality', selectedOptions)
  }

  const initialValues: FormInsitutionalData = {
    cue: institutoFilter[0]?.cue ?? '',
    nroProvincial: institutoFilter[0]?.provincialNumber ?? '',
    calle: institutoFilter[0]?.Address?.street ?? '',
    numero: institutoFilter[0]?.Address?.number ?? '',
    locality: selectedLocality,
    department: selectedDepartment,
    province: selectedProvince,
    region: institutoFilter[0]?.Region?.id ?? '',
    codigopostal: institutoFilter[0]?.Address?.postalCode ?? '',
    cantidadDeAlumnos: institutoFilter[0]?.studentsQuantity ?? '',
    cantidadDeEmpleados: institutoFilter[0]?.employeesQuantity ?? '',
    levelId: selectedLevels,
    modality: initialModalityValues?.map((e: any) => {
      return {
        levelModalityId: e,
      }
    }),
    typeInstitution: institutoFilter[0]?.institutionTypeId ?? '',
  }

  const validateSchema = Yup.object({
    cue: Yup.string().max(12, 'El CUE debe tener máximo 8 dígitos').required('Cue campo requerido'),
    nroProvincial: Yup.string().max(11, 'El Número provincial debe tener máximo 11 dígitos'),
    calle: Yup.string().min(1, 'Campo obligatorio').required('Calle campo requerido'),
    numero: Yup.string().min(1, 'Campo obligatorio').required('Número campo requerido'),
    codigopostal: Yup.string().max(5, 'Máximo de 5 dígitos').required('Campo requerido provincia'),
    locality: Yup.string().min(1, 'Campo requerido').required('Campo requerido localidad'),
    department: Yup.string().min(1, 'Campo requerido').required('Campo requerido departamento'),
    province: Yup.string().min(1, 'Campo requerido').required('Campo requerido provincia'),
    region:
      selectedProvince === '1'
        ? Yup.string().min(1, 'Campo requerido').required('Campo requerido región')
        : Yup.string(),
    cantidadDeAlumnos: Yup.string().required('Campo obligatorio'),
    cantidadDeEmpleados: Yup.string().required('Campo obligatorio'),
    typeInstitution: Yup.string().required('Campo requerido modalidad'),
  }).defined()

  const handleSubmit = (values: any) => {
    const Domicilio: DomicilioInstitutionForm = {
      id: institutoFilter[0]?.Address?.id ?? null,
      provinceId: selectedProvince,
      departmentId: selectedDepartment,
      localityId: selectedLocality,
      street: values.calle ?? '',
      number: values.numero ?? 0,
      postalCode: values.codigopostal ?? '',
    }

    const updatedInitialValuesForm: PostInstitutionForm = {
      id: institutoFilter[0]?.id ?? '',
      userId: state.user.userInformation.id,
      token: localStorage.getItem('access_token') ?? '',
      cue: values.cue ?? '',
      provincialNumber: values.nroProvincial ?? '',
      Address: Domicilio,
      region: values.region !== '' ? values.region : null,
      studentsQuantity: values.cantidadDeAlumnos ?? '',
      employeesQuantity: values.cantidadDeEmpleados ?? '',
      levelId: selectedLevels === '' ? null : selectedLevels,
      modality: values.modality,
      typeInstitution: values.typeInstitution === '' ? null : values.typeInstitution,
      email: institutoFilter[0]?.email ?? '',
      cuit: institutoFilter[0]?.cuit ?? '',
      name: institutoFilter[0]?.name ?? '',
      phoneNumber: institutoFilter[0]?.phoneNumber ?? '',
      businessName: institutoFilter[0]?.businessName ?? '',
      inProcess: institutoFilter[0]?.inProcess ?? '',
      webURL: institutoFilter[0]?.webURL ?? '',
      facebookURL: institutoFilter[0]?.facebookURL ?? '',
      instagramURL: institutoFilter[0]?.instagramURL ?? '',
      linkedinURL: institutoFilter[0]?.linkedinURL ?? '',
      twitterURL: institutoFilter[0]?.twitterURL ?? '',
      description: institutoFilter[0]?.description ?? '',
    }

    dispatch(institutionalDataRequest(updatedInitialValuesForm))
    dispatch(userInformationRequest({ userId: state.user.userInformation.id }))
  }

  return (
    <Box maxWidth='100%' margin='0 auto'>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validateSchema}
      >
        {({ errors, touched, setFieldValue, handleChange, values }) => {
          const fieldErrors = Object.values(errors)
          return (
            <Form>
              <Box>
                <Box display='flex' justifyContent='center'>
                  <Text
                    mb={4}
                    width={isMobile ? '100%' : '30%'}
                    fontWeight='bold'
                    textTransform={'uppercase'}
                  >
                    Datos Institucionales
                  </Text>
                </Box>
                <Box display={isMobile ? 'block' : 'flex'}>
                  <Box width={isMobile ? '100%' : '50%'} paddingRight={isMobile ? '0' : '2rem'}>
                    <Flex>
                      <FormControl width={institutoFilter[0]?.inProcess ? '90%' : '100%'}>
                        <FormLabel htmlFor='cue'>CUE*</FormLabel>
                        <Field
                          as={Input}
                          mb={4}
                          type='number'
                          min='0'
                          name='cue'
                          placeholder='Ingrese su CUE sin espacios ni puntos'
                          onChange={handleChange}
                        />
                        {errors.cue && touched.cue && (
                          <Text color='red' fontSize={'sm'}>
                            {errors.cue}
                          </Text>
                        )}
                      </FormControl>
                      {institutoFilter[0]?.inProcess && (
                        <Box ml={2} mt={1}>
                          <Tooltip label={textoToolkit}>
                            <span>
                              <InfoIcon boxSize={7} color='blue.500' />
                            </span>
                          </Tooltip>
                        </Box>
                      )}
                    </Flex>

                    <FormControl width={institutoFilter[0]?.inProcess ? '100%' : '100%'}>
                      <FormLabel htmlFor='nroProvincial'>Nro provincial*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='number'
                        min='0'
                        name='nroProvincial'
                        placeholder='Número provincial'
                        onChange={handleChange}
                      />
                      {errors.nroProvincial && touched.nroProvincial && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.nroProvincial}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='cantidadDeAlumnos'>Cantidad de alumnos*</FormLabel>
                      <Field as={Select} mb={4} name='cantidadDeAlumnos' onChange={handleChange}>
                        <option value=''>Elija una opción</option>
                        {alumnos?.map((item, i) => (
                          <option key={i} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Field>
                      {errors.cantidadDeAlumnos && touched.cantidadDeAlumnos && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.cantidadDeAlumnos}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='cantidadDeEmpleados'>Cantidad de empleados*</FormLabel>
                      <Field as={Select} mb={4} name='cantidadDeEmpleados' onChange={handleChange}>
                        <option value=''>Elija una opción</option>
                        {empleados?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Field>
                      {errors.cantidadDeEmpleados && touched.cantidadDeEmpleados ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.cantidadDeEmpleados}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='calle'>Calle*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='text'
                        name='calle'
                        placeholder='Ej: 24 de septiembre'
                      />
                      {errors.calle && touched.calle && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.calle}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='numero'>Número*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='number'
                        min='0'
                        name='numero'
                        placeholder='Ej : 125'
                      />
                      {errors.numero && touched.numero && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.numero}
                        </Text>
                      )}
                    </FormControl>
                  </Box>
                  <Box width={isMobile ? '100%' : '50%'}>
                    <FormControl>
                      <FormLabel htmlFor='province'>Provincia*</FormLabel>
                      <Field
                        as={Select}
                        mb={4}
                        name='province'
                        style={{ textTransform: 'capitalize' }}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleProvinceChange(e, setFieldValue)
                          setFieldValue('region', '')
                        }}
                        value={selectedProvince}
                      >
                        <option value=''>Seleccioná tu provincia</option>
                        {provincias?.map((provincia) => (
                          <option
                            style={{ textTransform: 'capitalize' }}
                            key={provincia.id}
                            value={provincia.id}
                          >
                            {provincia.description.toLowerCase()}
                          </option>
                        ))}
                      </Field>
                      {errors.province && touched.province && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.province}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='departament'>Departamento/Partido*</FormLabel>
                      <Field
                        as={Select}
                        mb={4}
                        style={{ textTransform: 'capitalize' }}
                        name='department'
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          handleDepartmentChange(e, setFieldValue)
                        }
                        value={selectedDepartment}
                      >
                        <option>Seleccioná tu departamento</option>
                        {departamentos?.map((departamento) => (
                          <option
                            style={{ textTransform: 'capitalize' }}
                            key={departamento.id}
                            value={departamento.id}
                          >
                            {departamento.description.toLowerCase()}
                          </option>
                        ))}
                      </Field>
                      {errors.department && touched.department && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.department}
                        </Text>
                      )}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='locality'>Localidad*</FormLabel>

                      <Field
                        as={Select}
                        mb={4}
                        name='locality'
                        style={{ textTransform: 'capitalize' }}
                        onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                          handleLocalityChange(e, setFieldValue)
                        }
                        value={selectedLocality}
                      >
                        <option value=''>Seleccioná tu ciudad</option>
                        {localidades?.map((localidad) => (
                          <option
                            style={{ textTransform: 'capitalize' }}
                            key={localidad.id}
                            value={localidad.id}
                          >
                            {localidad.description.toLowerCase()}
                          </option>
                        ))}
                      </Field>
                      {errors.locality && touched.locality ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.locality}
                        </Text>
                      ) : null}
                    </FormControl>

                    {selectedProvince == '1' && (
                      <FormControl>
                        <FormLabel htmlFor='region'>Región</FormLabel>
                        <Field as={Select} mb={4} name='region' onChange={handleChange}>
                          <option value=''>Elija una opción</option>
                          {regiones?.map((region, i) => (
                            <option key={region.id} value={region.id}>
                              {region.description}
                            </option>
                          ))}
                        </Field>
                        {errors.region && touched.region && (
                          <Text color='red' fontSize={'sm'}>
                            {errors.region}
                          </Text>
                        )}
                      </FormControl>
                    )}
                    <FormControl>
                      <FormLabel htmlFor='codigopostal'>Código postal*</FormLabel>
                      <Field
                        as={Input}
                        mb={4}
                        type='text'
                        name='codigopostal'
                        placeholder='Ej: 1050'
                      />
                      {errors.codigopostal && touched.codigopostal ? (
                        <Text color='red' fontSize={'sm'}>
                          {errors.codigopostal}
                        </Text>
                      ) : null}
                    </FormControl>
                    <FormControl>
                      <FormLabel htmlFor='levelId'>Nivel de enseñanza</FormLabel>
                      <Field
                        as={Select}
                        mb={4}
                        name='levelId'
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                          handleChange(e)
                          handleLevelsChange(e, setFieldValue)
                        }}
                      >
                        <option value=''>Elija una opción</option>
                        {educationsLevels.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Field>
                      {errors.levelId && touched.levelId && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.levelId}
                        </Text>
                      )}
                    </FormControl>
                    <Box>
                      <FormControl>
                        <FormLabel htmlFor='modality'>Característica</FormLabel>
                        <ReactSelect
                          placeholder='Elija una característica'
                          noOptionsMessage={() => 'No existen modalidades disponibles'}
                          isMulti
                          value={modality}
                          onChange={(value: any) => {
                            handleModalityChange(value, setFieldValue)
                          }}
                          options={
                            array?.length > 0
                              ? array.map((item, i) => {
                                  return { value: item.levelmodalityid, label: item.description }
                                })
                              : levelModalities
                                  ?.filter((e) => e.levelid === institutoFilter[0].levelId)
                                  ?.map((item, i) => {
                                    return { value: item.levelmodalityid, label: item.description }
                                  })
                          }
                        />
                      </FormControl>
                    </Box>
                    {/* hasta aqui  */}
                    <FormControl>
                      <FormLabel htmlFor='typeInstitution'>Modalidad</FormLabel>
                      <Field as={Select} mb={4} name='typeInstitution' onChange={handleChange}>
                        <option value=''>Elija una opción</option>
                        {institutionTypes.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.description}
                          </option>
                        ))}
                      </Field>
                      {errors.typeInstitution && touched.typeInstitution && (
                        <Text color='red' fontSize={'sm'}>
                          {errors.typeInstitution}
                        </Text>
                      )}
                    </FormControl>
                  </Box>
                </Box>
                <Box display='flex' justifyContent='center'>
                  <Button
                    mt={4}
                    colorScheme='blue'
                    width={isMobile ? '100%' : '20%'}
                    isDisabled={fieldErrors.length === 0 ? false : true}
                    type='submit'
                  >
                    Guardar Cambios
                  </Button>
                </Box>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default InsitutionData
