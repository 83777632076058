import { colors } from './../../../utils/colors'

export const adCardStyles = {
  container: {
    border: `1px solid ${colors.principal}`,
    padding: '20px 30px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'row',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  title: {
    color: colors.principal,
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '28px',
    margin: '0 0 10px 0',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  mainDataContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
    color: colors.principal,
    fontSize: '12px',
  },
  dataItem: { display: 'flex', alignItems: 'center' },
  adDataTitle: { fontWeight: 'bold' },
  adData: {
    textTransform: 'capitalize',
    textWrap: 'wrap',
  },
  imageContainer: { width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  infoContainer: { width: '85%' },
  actionContainer: {
    width: '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: '10px',
    color: colors.principal,
  },
  actionButton: {
    borderRadius: '5px',
    backgroundColor: `${colors.principal}`,
    border: `1px solid ${colors.principal}`,
    color: '#fff',
  },
  applied: {
    color: colors.red,
    fontWeight: 'bold',
    height: '2.5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  locationContainer: { display: 'flex', flexDirection: 'column', rowGap: '10px' },
  containerMobile: {
    border: `1px solid ${colors.principal}`,
    padding: '20px 30px',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  imageContainerMobile: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
  },
  infoContainerMobile: {
    width: '100%',
  },
  mainDataContainerMobile: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '15px',
    color: colors.principal,
    fontSize: '12px',
  },
  dataItemMobile1: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '5px',
    fontSize: '14px',
  },

  dataItemMobile2: {
    display: 'flex',
    fontSize: '14px',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '5px',
  },
  actionContainerMobile: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
  appliedMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.red,
    fontWeight: 'bold',
    height: '2.5rem',
  },
  avatarMobile: { width: '80px', height: '80px', borderRadius: '100px', objectFit: 'contain' },
  avatarImage: { width: '100px', height: '100px', borderRadius: '100px', objectFit: 'contain' },
}
