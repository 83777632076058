import * as Yup from 'yup'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useMediaQuery,
} from '@chakra-ui/react'
import { CloseIcon, QuestionIcon } from '@chakra-ui/icons'
import { FC, useEffect, useState } from 'react'
import { Field, Form, Formik, FormikErrors, FormikTouched, FormikValues } from 'formik'
import { PreferenceData, PreferenceFormField, PreferenceFormProps } from './_types'
import {
  institutionTypesClean,
  institutionTypesRequest,
  levelmodalitiesClean,
  levelmodalitiesRequest,
  levelsClean,
  levelsRequest,
  preferencesRequest,
} from '../../../../../state/modules/user/actions'
import {
  positionsClean,
  positionsRequest,
  subjectsClean,
  subjectsRequest,
} from '../../../../../state/modules/ads/actions'
import { preferenceFields, preferenceTypeOptions } from '../../../../../utils/profile'
import { useDispatch, useSelector } from 'react-redux'

import { JobExperienceFormStyles } from './styles'
import Select from 'react-select'
import { colors } from '../../../../../utils/colors'

const PreferenceForm: FC<PreferenceFormProps> = ({
  setShowPreference,
  setIsConfirmModalOpen,
}: PreferenceFormProps) => {
  const [isMobile] = useMediaQuery('(max-width: 600px)')
  const token = localStorage.getItem('access_token')
  const user = useSelector((state: any) => state?.user)
  const ads = useSelector((state: any) => state?.ads)
  const userInformation = useSelector((state: any) => state?.user?.userInformation)
  const [preferenceType, setPrerenceType] = useState<any>(preferenceTypeOptions[0])
  const [isTeacher, setIsTeacher] = useState(false)
  const [showOtherPosition, setShowOtherPosition] = useState<boolean>(false)
  const [showOtherEducationArea, setShowOtherEducationArea] = useState<boolean>(false)
  const [showOtherExtraEducationArea, setShowOtherExtraEducationArea] = useState<boolean>(false)
  const [levelOptions, setLevelOptions] = useState<any>([])
  const [levels, setLevels] = useState<any>([])
  const [teacherOptions, setTeacherOptions] = useState<any>([])
  const [notTeacherOptions, setNotTeacherOptions] = useState<any>([])
  const [positions, setPositions] = useState<any>([])
  const [educationOptions, setEducationOptions] = useState<any>([])
  const [extraEducationOptions, setExtraEducationOptions] = useState<any>([])
  const [educationAreas, setEducationAreas] = useState<any>([])
  const [extraEducationAreas, setExtraEducationAreas] = useState<any>([])
  const [levelModalitiesOptions, setLevelModalitiesOptions] = useState<any>([])
  const [levelModalities, setLevelModalities] = useState<any>([])

  const [institutionTypesOptions, setInstitutionTypesOptions] = useState<any>([])

  const [institutionTypes, setInstitutionTypes] = useState<any>([])
  const dispatch = useDispatch()
  const message =
    'Aquí ingresa las materias, talleres o disciplinas extracurriculares en los que te ofreces o buscas trabajo'

  useEffect(() => {
    dispatch(levelsRequest({ token }))
    dispatch(institutionTypesRequest({ token }))
    dispatch(positionsRequest({ token }))
    dispatch(subjectsRequest({ token }))
    dispatch(levelmodalitiesRequest({ token }))

    return () => {
      dispatch(levelsClean())
      dispatch(institutionTypesClean())
      dispatch(positionsClean())
      dispatch(subjectsClean())
      dispatch(levelmodalitiesClean())
    }
  }, [])

  useEffect(() => {
    if (user) {
      setLevelOptions(
        user?.levelsList?.map((item: any) => ({
          label: item.description,
          value: item.id,
        })),
      )

      setInstitutionTypesOptions(
        user?.institutionTypesList?.map((item: any) => ({
          label: item.description,
          value: item.id,
        })),
      )
    }
  }, [user])

  useEffect(() => {
    if (ads) {
      setTeacherOptions(
        ads?.positionsList
          ?.filter((item: any) => item.type === 'DOCENTE')
          .map((item: any) => ({
            label: item.description,
            value: item.id,
            hasObservation: item.hasObservation,
          })),
      )
      setNotTeacherOptions(
        ads?.positionsList
          ?.filter((item: any) => item.type === 'NO DOCENTE')
          .map((item: any) => ({
            label: item.description,
            value: item.id,
            hasObservation: item.hasObservation,
          })),
      )
      setEducationOptions(
        ads?.subjectsList
          ?.filter((item: any) => item.type === 'CURRICULAR')
          .map((item: any) => ({
            label: item.description,
            value: item.id,
            hasObservation: item.hasObservation,
          })),
      )
      setExtraEducationOptions(
        ads?.subjectsList
          ?.filter((item: any) => item.type === 'EXTRA-CURRICULAR')
          .map((item: any) => ({
            label: item.description,
            value: item.id,
            hasObservation: item.hasObservation,
          })),
      )
    }
  }, [ads])

  const renderInput = (
    field: PreferenceFormField,
    setFieldValue: any,
    handleChange: any,
    resetForm: any,
    setFieldTouched: any,
  ) => {
    switch (field.id) {
      case 'preferenceType':
        return (
          <Select
            placeholder='Selecciona el tipo de preferencia'
            value={preferenceType}
            onChange={(value) => handlePreferenceTypeChange(value, resetForm)}
            name={field.name}
            options={preferenceTypeOptions}
          />
        )

      case 'level':
        return (
          <Select
            placeholder='Selecciona el nivel'
            isMulti
            value={levels}
            onChange={(value) => handleLevelChange(value, setFieldValue, field.id)}
            name={field.name}
            options={levelOptions}
            onBlur={() => setFieldTouched(field.id)}
          />
        )
      case 'levelModalities':
        return (
          <Select
            placeholder='Selecciona la característica'
            isMulti
            value={levelModalities}
            onChange={(value) => handleLevelModalitiesChange(value, setFieldValue, field.id)}
            name={field.name}
            options={levelModalitiesOptions}
            onBlur={() => setFieldTouched(field.id)}
          />
        )
      case 'institutionTypes':
        return (
          <Select
            placeholder='Selecciona la modalidad'
            isMulti
            value={institutionTypes}
            onChange={(value) => handleInstitutionTypesChange(value, setFieldValue, field.id)}
            name={field.name}
            options={institutionTypesOptions}
            onBlur={() => setFieldTouched(field.id)}
          />
        )
      case 'position':
        return (
          <Select
            placeholder='Selecciona el puesto de trabajo'
            isMulti
            value={positions}
            onChange={(value) => handlePositionChange(value, setFieldValue, field.id)}
            name={field.name}
            options={preferenceType.value === 'docente' ? teacherOptions : notTeacherOptions}
            onBlur={() => setFieldTouched(field.id)}
          />
        )
      case 'educationAreas':
        return (
          <Select
            isDisabled={extraEducationAreas.length > 0 ? true : false}
            placeholder='Selecciona el puesto de trabajo'
            isMulti
            options={educationOptions}
            onChange={(value) => handleEducationAreasChange(value, setFieldValue, field.id)}
            name={field.name}
            value={educationAreas}
            onBlur={() => setFieldTouched(field.id)}
          />
        )

      case 'extraEducationAreas':
        return (
          <Select
            placeholder='Selecciona el puesto de trabajo'
            isMulti
            isDisabled={educationAreas.length > 0 ? true : false}
            options={extraEducationOptions}
            value={extraEducationAreas}
            onChange={(value) => handleExtraEducationAreasChange(value, setFieldValue, field.id)}
            name={field.name}
            onBlur={() => setFieldTouched(field.id)}
          />
        )

      default:
        return (
          <Field
            as={Input}
            type={field.type}
            maxLength={field.length ?? undefined}
            name={field.id}
          />
        )
    }
  }

  const initialValues = {
    preferenceType: '',
    level: [],
    position: [],
    otherPosition: '',
    educationAreas: [],
    otherEducationArea: '',
    extraEducationAreas: [],
    otherExtraEducationArea: '',
    intendedSalary: '',
    institutionTypes: [],
    levelModalities: [],
  }
  const handlePreferenceTypeChange = (value: any, resetForm: any) => {
    setPrerenceType(value)
    setLevels([])
    setIsTeacher(false)
    setPositions([])
    setEducationAreas([])
    setExtraEducationAreas([])
    setShowOtherPosition(false)
    setShowOtherEducationArea(false)
    setShowOtherExtraEducationArea(false)
    resetForm({ values: initialValues })
  }

  const handleLevelChange = (value: any, setFieldValue: any, name: string) => {
    setLevelModalitiesOptions([])
    setLevelModalities([])
    setTimeout(() => {
      setFieldValue('levelModalities', [])
    }, 200)
    setLevels(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value }
      }),
    )

    value.forEach((level: any) => {
      const levelModalities = user?.levelModalitiesList?.filter(
        (item: any) => item.levelid === level.value,
      )

      if (levelModalities?.length > 0) {
        const options = levelModalities.map((item: any) => ({
          label: item.description,
          value: item.levelmodalityid,
        }))
        setLevelModalitiesOptions((prev: any) => {
          return [...prev, ...options]
        })
      }
    })
  }

  const handleLevelModalitiesChange = (value: any, setFieldValue: any, name: string) => {
    setLevelModalities(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return item.value
      }),
    )
  }

  const handlePositionChange = (value: any, setFieldValue: any, name: string) => {
    setPositions(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value, hasObservation: item.hasObservation }
      }),
    )

    const isProfesor = value.find((item: any) => item.value === '20')
    const isOtherPosition = value.find((item: any) => item.hasObservation)

    if (isProfesor) {
      setIsTeacher(true)
    } else {
      setIsTeacher(false)
    }

    if (isOtherPosition) {
      setShowOtherPosition(true)
    } else {
      setTimeout(() => {
        setFieldValue('otherPosition', '')
      }, 500)
      setShowOtherPosition(false)
    }
  }

  const handleEducationAreasChange = (value: any, setFieldValue: any, name: string) => {
    setEducationAreas(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value, hasObservation: item.hasObservation }
      }),
    )

    const isOtherArea = value.find((item: any) => item.hasObservation)
    if (isOtherArea) {
      setShowOtherEducationArea(true)
    } else {
      setTimeout(() => {
        setFieldValue('otherEducationArea', '')
      }, 500)

      setShowOtherEducationArea(false)
    }
  }

  const handleExtraEducationAreasChange = (value: any, setFieldValue: any, name: string) => {
    setExtraEducationAreas(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return { id: item.value, hasObservation: item.hasObservation }
      }),
    )

    const isOtherArea = value.find((item: any) => item.hasObservation)
    if (isOtherArea) {
      setShowOtherExtraEducationArea(true)
    } else {
      setTimeout(() => {
        setFieldValue('otherExtraEducationArea', '')
      }, 500)

      setShowOtherExtraEducationArea(false)
    }
  }

  const handleInstitutionTypesChange = (value: any, setFieldValue: any, name: string) => {
    setInstitutionTypes(value)
    setFieldValue(
      name,
      value.map((item: any) => {
        return item.value
      }),
    )
  }

  const validateSchema = Yup.object({
    preferenceType: Yup.string(),
    level: preferenceType.value === 'docente' ? Yup.array().min(1, 'Campo requerido') : Yup.array(),
    position: Yup.array().min(1, 'Campo requerido'),
    levelModalities: Yup.array(),
    institutionTypes:
      preferenceType.value === 'docente' ? Yup.array().min(1, 'Campo requerido') : Yup.array(),
    extraEducationAreas:
      isTeacher && educationAreas.length === 0
        ? Yup.array().min(1, 'Campo requerido')
        : Yup.array(),
    educationAreas:
      isTeacher && extraEducationAreas.length === 0
        ? Yup.array().min(1, 'Campo requerido')
        : Yup.array(),
    otherPosition: showOtherPosition ? Yup.string().required('Campo requerido') : Yup.string(),
    otherEducationArea: showOtherEducationArea
      ? Yup.string().required('Campo requerido')
      : Yup.string(),
    otherExtraEducationArea: showOtherExtraEducationArea
      ? Yup.string().required('Campo requerido')
      : Yup.string(),
    intendedSalary: Yup.string(),
  }).defined()

  const handleSubmit = (values: FormikValues) => {
    const token = localStorage.getItem('access_token') ?? ''

    const positions = values.position.map((item: any) => {
      return item.hasObservation
        ? { id: Number(item.id), observation: values.otherPosition }
        : { id: Number(item.id), observation: null }
    })
    const educationAreas = values.educationAreas.map((item: any) => {
      return item.hasObservation
        ? { id: Number(item.id), observation: values.otherEducationArea }
        : { id: Number(item.id), observation: null }
    })

    const extraEducationAreas = values.extraEducationAreas.map((item: any) => {
      return item.hasObservation
        ? { id: Number(item.id), observation: values.otherExtraEducationArea }
        : { id: Number(item.id), observation: null }
    })

    const upDatedValues: PreferenceData = {
      intendedSalary: values.intendedSalary,
      levels: values.level,
      levelModalities: values.levelModalities,
      institutionTypes: values.institutionTypes,
      positions,
      subjects: isTeacher ? [...educationAreas, ...extraEducationAreas] : [],
    }

    dispatch(preferencesRequest({ token, ...upDatedValues }))
    setShowPreference(false)
    setIsConfirmModalOpen(true)
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validateSchema}>
      {({ errors, touched, setFieldValue, handleChange, resetForm, setFieldTouched }) => {
        const touchedFields = touched as FormikTouched<any>
        const errorFields = errors as FormikErrors<any>

        return (
          <Form style={isMobile ? { width: '100%' } : { width: '70%' }}>
            <Box sx={JobExperienceFormStyles.form}>
              <Button
                sx={JobExperienceFormStyles.closeButton}
                onClick={() => setShowPreference(false)}
              >
                <CloseIcon />
              </Button>
              {preferenceFields.map((field: PreferenceFormField, index) => {
                const hidden =
                  field.category === 'teacher'
                    ? !isTeacher
                      ? 'none'
                      : (field.id === 'otherEducationArea' && !showOtherEducationArea) ||
                        (field.id === 'otherExtraEducationArea' && !showOtherExtraEducationArea)
                      ? 'none'
                      : 'initial'
                    : field.id === 'otherPosition' && !showOtherPosition
                    ? 'none'
                    : field.id === 'level' ||
                      field.id === 'levelModalities' ||
                      field.id === 'institutionTypes'
                    ? preferenceType.value === 'nodocente'
                      ? 'none'
                      : 'initial'
                    : 'initial'
                return (
                  <FormControl
                    display={hidden}
                    sx={
                      isMobile
                        ? { ...JobExperienceFormStyles.formControl, width: '90%' }
                        : { ...JobExperienceFormStyles.formControl }
                    }
                    key={index}
                  >
                    <FormLabel>
                      {field.name}
                      {field.required && '*'}
                      {field.id === 'extraEducationAreas' &&
                        (isMobile ? (
                          <Popover>
                            <PopoverTrigger>
                              <Button backgroundColor='transparent'>
                                <QuestionIcon color={colors.principal} />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody sx={JobExperienceFormStyles.popOverMobile}>
                                {message}
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        ) : (
                          <Tooltip label={message}>
                            <QuestionIcon ml={2} />
                          </Tooltip>
                        ))}
                    </FormLabel>
                    {renderInput(field, setFieldValue, handleChange, resetForm, setFieldTouched)}
                    {errorFields[`${field.id}`] && touchedFields[`${field.id}`] && (
                      <Text color='red' fontSize={'sm'}>{`${errorFields[field.id]}`}</Text>
                    )}
                  </FormControl>
                )
              })}
              <Button sx={JobExperienceFormStyles.saveButton} type='submit'>
                Guardar
              </Button>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

export default PreferenceForm
